import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
// import LanguageIcon from '@mui/icons-material/Language';
import LanguageIcon from '@material-ui/icons/Language';

import {
  useMediaQuery, Theme,
  MenuItem, ListItemIcon, makeStyles, createStyles, IconProps,
} from '@material-ui/core';

const useStyles = makeStyles(
  (theme: Theme) => createStyles({
    menuItem: {
      color: theme.palette.text.secondary,
    },
    icon: { minWidth: theme.spacing(5) },
    languageLabel: {
      fontSize: '12px',
      padding: '0 5px 0 0',
      wordBreak: 'keep-all',
    }
  })
  // { name: 'RaLogout' }
);

const LanguageMenu: FunctionComponent<{icon?: IconProps}> = (props) => {
  const { icon } = props;
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );

  const [language, setLanguage] = useState(i18n.language);
  const [anchorEl, setAnchorEl] = useState(null);
  
  const open = Boolean(anchorEl);

  const getLanguageLabel = () => {
    if (language.indexOf('zh') !== -1) {
      return '中文';
    } else {
      return 'English';
    }
  };
  const handleMenu = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleChange = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    handleClose();
    window.location.reload();
  };

  return (
    <div>
      <Tooltip title={t('auth.user_menu')}>
        <IconButton
          aria-label={t('auth.user_menu')}
          aria-owns={open ? 'menu-appbar' : null}
          aria-haspopup={true}
          color="inherit"
          onClick={handleMenu}
        >
          {icon}
          <div className={classes.languageLabel}>{getLanguageLabel()}</div>
        </IconButton>
      </Tooltip>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem className={classes.menuItem} onClick={() => handleChange('zh')} component={isXSmall ? 'span' : 'li'}>中文</MenuItem>
        <MenuItem className={classes.menuItem} onClick={() => handleChange('en')} component={isXSmall ? 'span' : 'li'}>English</MenuItem>
      </Menu>
    </div>
  );
};


LanguageMenu.defaultProps = {
  icon: <LanguageIcon />
};

export default LanguageMenu;
