import _, { get, isEmpty } from 'lodash';
import React, {useState, useEffect, useContext} from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import {
  Checkbox, Radio, Box, Grid, Switch,
  FormControlLabel, FormGroup, FormControl, FormLabel,
  Typography, CheckboxProps, createStyles, Snackbar, RadioGroup
} from '@material-ui/core';
import { Validate } from './validate';
import { TenantContext } from '../../orbit-context';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

interface CheckboxGroupsInputProps<T> extends CheckboxProps {
  name: string;
  label?: string;
  validate?: Validate[];
  options: Array<T>;
  getOptionKey?: (option: T) => React.Key;
  getOptionLabel?: (option: T) => string | number;
  getOptionValue?: (option: T) => unknown;
  getDisabled?: (option: T) => boolean;
  row?: boolean;
  disabled?: boolean;
  // hasRequired?: boolean;
}

const defaultGetOptionKey = (option) => get(option, 'id');
const defaultGetOptionLabel = (option) => get(option, 'label');
const defaultgetOptionValue = (option) => {
  return get(option, 'value');
};

export default function CheckboxGroupsInput<T>(props: CheckboxGroupsInputProps<T>) {
  const {
    name, label = name,
    validate = [], row,
    options,
    disabled,
    getOptionKey = defaultGetOptionKey,
    getOptionLabel = defaultGetOptionLabel,
    getOptionValue = defaultgetOptionValue,
    getDisabled = () => disabled,
    // hasRequired,
    ...rest
  } = props;
  const [t] = useTranslation();
  // const [checkState, setCheckState] = useState({});
  // useEffect(() => {
  //   if (hasRequired) {
  //     const obj = {};
  //     options.map((item) => {
  //       obj[item.id] = item.id === 'phone' ? true : false;
  //     });
  //     setCheckState(obj);
  //   }
  // }, [hasRequired]);
  const methods = useFormContext();
  const { errors, control, getValues } = methods;
  const me = useForm();
  const defaultValue = get(control.defaultValuesRef.current, name, []);
  const tenant  = useContext(TenantContext);
  const error = get(errors, name);
  const errorMessage = get(errors, [name, 'message']);
  const [berror, setBError] = useState('');

  const validations = validate.reduce((acc, v) => Object.assign(acc, v), {});
  const required = Boolean(get(validations, 'required', false));

  const getOptionSelected = (values = [], option) => {
    return values.includes(getOptionValue(option));
  };
  const styles: any = createStyles({
    formControlLabel: { fontSize: '0.9rem', 
      '& label': { fontSize: '0.9rem' } }
  });
  const handleChange = (e, isChecked, onChange) => {
   
    const oldValues = getValues(name);
    const value = e.target.value;
    // console.log('value tenant', {value}, {tenant});
    // businesstype     businessType 
    if (value === 'industry' && isChecked) {
      if ((!tenant?.info?.industries)||tenant?.info?.industries?.length==0) {
        onChange([].concat(oldValues.filter(v => v !== value)));
        if (berror.length==0)
          setBError(t('seetings.industryTips'));
        return;
      }
    }
    if (value === 'businesstype' && isChecked) {
      const btList = _.get(tenant, ['info', 'businessType'], []);
      if (_.isEmpty(btList)) {
        onChange([].concat(oldValues.filter(v => v !== value)));
        if (berror.length==0)
          // setBError("请先设置业务类型后再开启该选项！");
          setBError(t('seetings.bussinessTips'));
        return;
      }
    }



    const newValues = isChecked
      ? [value].concat(oldValues)
      : [].concat(oldValues.filter(v => v !== value));
    onChange(newValues);
  };

  return (
    <FormControl component="fieldset"
      required={required} error={Boolean(error)}
      margin="normal"
    >
      {berror.length>0&&<Snackbar open={berror.length!=0} autoHideDuration={3000} onClose={()=>{setBError('')}}>
        <Alert onClose={()=>{setBError('')}} severity={'error'}>{ berror }</Alert>
      </Snackbar>}
      {/* <FormLabel >{label}</FormLabel> */}
      <FormGroup row={row} title={label} >
        {options.map((option) => (
          <Controller key={getOptionKey(option)}
            control={control} name={name} rules={validations}
            defaultValue={defaultValue}
            render={({ onChange, onBlur, value, name }) => (
              <Box>
                <FormControlLabel
                  label={<Typography style={styles.formControlLabel}>{getOptionLabel(option)}</Typography>}
                  control={<Checkbox
                    color="primary"
                    name={name}
                    checked={getOptionSelected(value, option)}
                    value={getOptionValue(option)}
                    onChange={(e, isChecked) => {
                      handleChange(e, isChecked, onChange);
                    }}
                    onBlur={onBlur}
                    // disabled={getDisabled(option)}
                    disabled={option?.disable ?? false}
                    {...rest}
                  />}
                />
                {/* {get(option, ['hasRequired'], false) && <Grid component="label" container alignItems="center" spacing={1} style={{ width: '150px' }}>
                  <Grid item>必填</Grid>
                  <Grid item style={{padding: 0}}>否</Grid>
                  <Grid item style={{padding: 0}}>
                    <Switch
                      disabled={!option.changeable}
                      checked={option.require}
                      onChange={(e) => option.require = !e.target.checked}
                      name="checkedPhone"
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </Grid>
                  <Grid item style={{padding: 0}}>是</Grid>
                </Grid>} */}
              </Box>
            )}>
          </Controller>
        ))}
      </FormGroup>
      <Typography variant="caption" color="error">
        {errorMessage}
      </Typography>
    </FormControl>
  );
}
// 触发时机 单选框
export function RadioboxGroupsInput<T>(props: CheckboxGroupsInputProps<T>) {
  const {
    name, label = name,
    validate = [], row,
    options,
    disabled,
    defaultValues,
    getOptionKey = defaultGetOptionKey,
    getOptionLabel = defaultGetOptionLabel,
    getOptionValue = defaultgetOptionValue,
    getDisabled = () => disabled,
    // hasRequired,
    ...rest
  } = props;

  const methods = useFormContext();
  const { errors, control, getValues, setValue } = methods;
  

  const defaultValue = get(defaultValues??control.defaultValuesRef.current, name, '');

  const [radioValue, setRadioValue] = useState(defaultValue);

  // if (isEmpty(defaultValue)) {
  //   setValue(name, 'view');
  // }
  const getValueByName = getValues(name);
  useEffect(() => {
    if (!isEmpty(getValueByName)) {
      setRadioValue(getValueByName);
    }
  }, [getValueByName]);

  const error = get(errors, name);
  const errorMessage = get(errors, [name, 'message']);
  const [berror, setBError] = useState('');

  const validations = validate.reduce((acc, v) => Object.assign(acc, v), {});
  const required = Boolean(get(validations, 'required', false));

  const getOptionSelected = (values = [], option) => {
    return values.includes(getOptionValue(option));
  };
  const styles: any = createStyles({
    formControlLabel: { fontSize: '0.9rem', 
      '& label': { fontSize: '0.9rem' } }
  });
  const handleChange = (e, isChecked, onChange) => {
    if (isChecked) {
      const value = e.target.value;
      onChange(value);
    }
  };
  // console.log('getValues(name)getValues(name)getValues(name)', getValues(name));
  return (
    <FormControl component="fieldset"
      required={required} error={Boolean(error)}
      margin="normal"
    >
      {berror.length>0&&<Snackbar open={berror.length!=0} autoHideDuration={3000} onClose={()=>{setBError('')}}>
        <Alert onClose={()=>{setBError('')}} severity={'error'}>{ berror }</Alert>
      </Snackbar>}
      {/* <FormLabel >{label}</FormLabel> */}
      <FormGroup row={row} title={label} >
        <Controller 
          control={control} 
          name={name} 
          rules={validations}
          defaultValue={defaultValue}
          render={({ onChange, onBlur, value, name }) => (
            <RadioGroup 
              row={row}
              value={radioValue} 
              // defaultValue={isEmpty(defaultValue) ? getValueByName : defaultValue}
            >
              {options.map((option) => (
                <FormControlLabel
                  key={getOptionKey(option)}
                  label={<Typography style={styles.formControlLabel}>{getOptionLabel(option)}</Typography>}
                  value={getOptionValue(option)}
                  // defaultValue={defaultValue}
                  onChange={(e, isChecked) => {
                    handleChange(e, isChecked, onChange);
                  }}
                  control={<Radio
                    color="primary"
                    name={name}
                    checked={getOptionSelected(value, option)}
                    onBlur={onBlur}
                    // disabled={getDisabled(option)}
                    disabled={option?.disable ?? false}
                    {...rest}
                  />}
                />
              ))}
            </RadioGroup>
          )}>
        </Controller>
      </FormGroup>
      <Typography variant="caption" color="error">
        {errorMessage}
      </Typography>
    </FormControl>
  );
}

export  function UploadCheckboxGroupsInput<T>(props: CheckboxGroupsInputProps<T>) {
  const {
    name, label = name,
    validate = [], row,
    options,
    disabled,
    getOptionKey = defaultGetOptionKey,
    getOptionLabel = defaultGetOptionLabel,
    getOptionValue = defaultgetOptionValue,
    getDisabled = () => disabled,
    ...rest
  } = props;
  const methods = useFormContext();
  const { errors, control, getValues } = methods;
  const defaultValue = get(control.defaultValuesRef.current, name, []);
  const error = get(errors, name);
  const errorMessage = get(errors, [name, 'message']);

  const validations = validate.reduce((acc, v) => Object.assign(acc, v), {});
  const required = Boolean(get(validations, 'required', false));

  const getOptionSelected = (values = [], option) => {
    return values.includes(getOptionValue(option));
  };
  const styles: any = createStyles({
    formControlLabel: { fontSize: '0.9rem', 
      '& label': { fontSize: '0.9rem' } }
  });
  const handleChange = (e, isChecked, onChange) => {
    const oldValues = getValues(name);
    const value = e.target.value;
    const newValues = isChecked
      ? [value].concat(oldValues)
      : [].concat(oldValues.filter(v => v !== value));
    onChange(newValues);
  };

  return (
    <FormControl component="fieldset"
      required={required} error={Boolean(error)}
      margin="normal"
    >
      <FormLabel >{label}</FormLabel>
      <FormGroup row={row} title={label} >
        {options.map((option) => (
          <Controller key={getOptionKey(option)}
            control={control} name={name} rules={validations}
            defaultValue={defaultValue}
            render={({ onChange, onBlur, value, name }) => (
              <FormControlLabel
                label={<Typography style={styles.formControlLabel}>{getOptionLabel(option)}</Typography>}
                control={<Checkbox
                  color="primary"
                  name={name}
                  checked={getOptionSelected(value, option)}
                  value={getOptionValue(option)}
                  onChange={(e, isChecked) => {
                    handleChange(e, isChecked, onChange);
                  }}
                  onBlur={onBlur}
                  disabled={getDisabled(option)}
                  {...rest}
                />}
              />
            )}>
          </Controller>
        ))}
      </FormGroup>
      <Typography variant="caption" color="error">
        {errorMessage}
      </Typography>
    </FormControl>
  );
}