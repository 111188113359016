import * as React from 'react';
import {
  Dialog, DialogTitle, DialogProps, Typography, IconButton,
  makeStyles, createStyles, Theme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }
  })
);

interface SampleDialogProps extends DialogProps {
  open: boolean;
  toggle: () => void;
  children: React.ReactNode;
  title?: string;
}

/**
 *
 * @deprecated 用处不大的封装
 */
export default function SampleDialog (props: SampleDialogProps) {
  const {
    open, toggle,
    children,
    title,
    ...rest
  } = props;
  const classes = useStyles();
  const handleClose = (event, reason) => {
    // console.log('reasonreasonreasonreason', reason);
  };

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      // onClose={toggle}
      // disableBackdropClick
      onClose={handleClose}
      fullWidth
      {...rest}
    >
      {title && <DialogTitle 
        className={classes.title} 
        disableTypography
      >
        <Typography variant='h6'>
          {title}
        </Typography>
        <IconButton 
          aria-label="close" 
          size='small' 
          onClick={toggle}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>}
      {
        children
      }
    </Dialog>
  );
}