import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  useHistory,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  Box,
  Toolbar, Container,
  Typography,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { getInvitationFormAction } from '@ivymobi/orbit-core/business/tenant';
import { sendLoginMobileCodeAction } from '@ivymobi/orbit-core/business/user';
import { User } from '@ivymobi/orbit-core';
import { useTranslation } from 'react-i18next';
import { isEmpty, get } from 'lodash';

import { listTenantsAction } from '@ivymobi/orbit-core/business/tenant';


import {
  Form, FormDataConsumer,
  TextInput, SaveButton,
  required
} from '../components';
import Loader from '../components/loaders';
import { SendSmsCodeButton } from './smsCode';
import { useForm } from 'react-hook-form';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import { flush } from 'redux-saga/effects';
import en from 'react-phone-number-input/locale/en.json';
import LanguageMenu from '../layout/languageMenu';



interface InvitationFormProps {
  id: string;
  user: { token: string; user: User };
  inviter: { id: string };
  acceptInvitation: (data) => Promise<void>;
  doSendCode: (phone: string,countryCode: string) => Promise<void>;
}
const  IsPC = () => {
  const userAgentInfo = navigator.userAgent;
  const Agents = ['Android', 'iPhone',
    'SymbianOS', 'Windows Phone',
    'iPad', 'iPod'];
  let flag = true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
};
function InvitaionForm(props: InvitationFormProps) {
  const [t] = useTranslation();
  const { user, id, acceptInvitation, doSendCode } = props;
  const { register, handleSubmit, errors, getValues, watch, formState, setValue } = useForm<{
    mobile: string;
    code: string;
    countryCode: string;
  }>({ mode: 'onChange', defaultValues: { mobile: '', code: '', countryCode: '+86' } });
  const phone = user.user?.phone;
  const [flush, setFlush] = useState(true);

  const defaultValue = { invitationId: id, phone };
  const FormToolBar = () => {
    return (
      <Toolbar style={{ paddingLeft: '32px', paddingRight: '32px' }}>
        <SaveButton fullWidth startIcon={null} size="large"
          disabled={false} label={t('YBT-ADMIN.YBT-ADMIN-040402.YBT-ADMIN-04040208')}
        />
      </Toolbar>
    );
  };
  register({ name: 'countryCode' });

  const handleChange = (event) => {
    setValue('countryCode', '+' + event.target.value);
    setFlush(!flush);
    // console.log('======', getValues('countryCode').replace('+', ''));
  };

  const CountrySelect = ({ labels }) => {
    return (
      <FormControl  required >
        <InputLabel id="demo-simple-select-label">{t('login.country')}</InputLabel>
        <Select style={{padding:'4px 10px 10px 0px', width:'100px'}}
          name='countryCode'
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={getValues('countryCode').replace('+', '')}
          label="countryCode"
          onChange={handleChange}
        >
          {getCountries().map((country) => (
            <MenuItem key={country} value={getCountryCallingCode(country)}>{labels[country]}</MenuItem>

          ))}
        </Select>
      </FormControl>
    );
  };
  return (
    <Form 
      onSubmit={(data) => {
        acceptInvitation({...data, countryCode:getValues('countryCode')});
      }} 
      defaultValues={defaultValue}
      toolbar={<FormToolBar />}
    >
      <TextInput name="name" label={t('YBT-ADMIN.YBT-ADMIN-030101.YBT-ADMIN-03010106')} fullWidth validate={[required()]}/>
      <Box  style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        { !phone && 
          <CountrySelect
            labels={en}
            value={getValues('countryCode')}
            onChange={(e) => console.log(e)} 
          />
        }
        <TextInput 
          name="phone" 
          label={t('YBT-ADMIN.YBT-ADMIN-030101.YBT-ADMIN-03010107')} 
          style={{flexGrow:1}} 
          validate={[required()]} 
          disabled={!!phone}
          InputProps={{
            startAdornment: <InputAdornment position="start">{getValues('countryCode')}</InputAdornment>,
          }}
        />
    
      </Box>
      
      {!phone && <FormDataConsumer>
        {({ formData }) => (
          <TextInput name="code" label={t('share.form.code')} fullWidth validate={[required()]}
            autoComplete='new-password'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SendSmsCodeButton
                    doSendCode={() => doSendCode(formData.phone,getValues('countryCode'))}
                    disabled={!formData.phone}
                  />
                </InputAdornment>
              )
            }}
          />
        )}
      </FormDataConsumer>}
      <TextInput name="email" label={t('YBT-ADMIN.YBT-ADMIN-040402.YBT-ADMIN-04040205')} fullWidth validate={[required()]} type='email' />
      <TextInput name="department" label={t('YBT-ADMIN.YBT-ADMIN-040402.YBT-ADMIN-04040206')}
        fullWidth validate={[required()]}
      />
      <TextInput name="title" label={t('YBT-ADMIN.YBT-ADMIN-030103.YBT-ADMIN-03010319')} fullWidth validate={[required()]}/>
    </Form>
  );
}

export default function Invitations(props) {
  const history = useHistory();
  const [t] = useTranslation();
  const { user, acceptInvitation } = props;
  const { invitationId } = useParams<{invitationId: string}>();
  const [invitation, setInvitation] = useState(null);

  const dispatch = useDispatch();

  // isMember 当前登录账号已经是该团队成员？ boolean
  const [isMember, setIsMember] = useState(false);

  useEffect(() => {
    if (!isEmpty(user) && !isEmpty(invitation) ) {
      const tenantid = get(invitation, ['tenant', 'id'], '');
      dispatch(
        listTenantsAction({user})
      ).then((res) => {
        
        const filterIndex = res.findIndex((item) => {
          return item.id === tenantid;
        });
        // console.log('resresresres', res);
        // console.log('invitationIdinvitationIdinvitationId', invitationId);
        // console.log('filterIndexfilterIndexfilterIndex', filterIndex);
        filterIndex === -1 ? setIsMember(false) : setIsMember(true);
      });

    } else {
      setIsMember(false);
    }

  }, [dispatch, user, invitation]);



  useEffect(() => {
    dispatch(getInvitationFormAction({ id: invitationId }))
    .then((res) => setInvitation(res), (error) => setInvitation({ error }));
  }, [dispatch, setInvitation]);

  const doSendCode = (mobile,countryCode) => dispatch(sendLoginMobileCodeAction({ mobile,countryCode }));

  const handleAcceptInvitation = async (data) => {
    const res = await acceptInvitation(invitationId, data);
    isEmpty(res) ? '' : history.push('/choose');
    // 刷新页面，直接跳转到choose 会导致页面loading无法获取信息
    //location.reload();
  };

  if (!invitation) {
    return <Loader  classtag='test14141414' />;
  }

  if (invitation.error) {
    return <p>{invitation.error.message}</p>;
  }
  return (
    <Box>
      <Box  style={{width:'98%',display:'flex',justifyContent:'flex-end'}}>
        <LanguageMenu></LanguageMenu>
      </Box>
      <Container maxWidth="sm" >
      
        <Box p={2} />
        <Typography variant={"h6"} align="center" gutterBottom>
          {invitation.inviter.name} {t('YBT-ADMIN.YBT-ADMIN-040402.YBT-ADMIN-04040201')}
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="center" >
          <img style={{ objectFit: 'contain', minWidth: '32px', height: '32px' }}
            src={invitation.tenant.info.logo}
          />
          <Box p={1} />
          <Typography variant={IsPC()? "h4" : 'h5'}>{invitation.tenant.name}</Typography>
        </Box>
        <Box p={2} />
        {!isMember && <Typography align="center" variant="subtitle2" color="textSecondary" >
          {t('YBT-ADMIN.YBT-ADMIN-040402.YBT-ADMIN-04040202')}
        </Typography>}
        {!isMember && <InvitaionForm id={invitationId} inviter={invitation.inviter}
          dispatch={dispatch}
          doSendCode={doSendCode}
          {...props}
          acceptInvitation={handleAcceptInvitation}
        />}
        {isMember && <Box>
          <Typography align="center" variant="h4" >
            {/* 您已加入该团队 */}
            {t('YBT-ADMIN.YBT-ADMIN-040402.YBT-ADMIN-04040216')}
          </Typography>
          <img style={{ objectFit: 'contain', display: 'block', margin: '20px auto', width: '80%', height: 'auto' }}
            src='/images/send_success.svg'
          />

        </Box>}
      </Container>
    </Box>
   
  );
}
