import * as React from 'react';
import { useFormContext } from 'react-hook-form';

interface FormDataConsumerProps {
  children: ({ formData }) => React.ReactNode;
}
export default function FormDataConsumer(props: FormDataConsumerProps) {
  const { children } = props;
  const { watch } = useFormContext();
  const formData = watch();

  return <>{children({ formData })}</>;
}