import * as React from 'react';
import { useParams } from 'react-router';
import {
  Box,
} from '@material-ui/core';
import { User } from '@ivymobi/orbit-core';
import { useEffect } from 'react';


export default function WechatCode(props) {
  const { search } = location
  const paramsString = search.substring(1)
  const searchParams = new URLSearchParams(paramsString)  
  const { login } = props;
  

  useEffect(() => {
    login({code:searchParams.get('code')});
  }, []);



  return (
    <Box></Box>
  );
}