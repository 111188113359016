
import React, {
  lazy, Suspense, useCallback, useEffect, useState, useMemo,
} from 'react';
import { Provider, useDispatch } from 'react-redux';
import {
  BrowserRouter, Route, Switch, Redirect, useHistory,
} from 'react-router-dom';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';

import {
  CssBaseline, ThemeProvider, createTheme,
} from '@material-ui/core';
import {
  makeStyles, Theme, createStyles,
} from '@material-ui/core/styles';

import * as api from './api';
import {
  loginAction, logoutAction, signupAction,
  resumeLastSessionAction, acceptInvitationWithRegisterAction, wxloginAction,
} from '@ivymobi/orbit-core/business/user';
import configureStore from '@ivymobi/orbit-core/business/configureStore';
import { acceptInvitationWithLoginedSessionAction, getWechatAccessTokenByCode } from '@ivymobi/orbit-core/business/tenant';

import './services/i18n';
import { isEmpty, isNull } from 'lodash';

import { ThemeContext } from './orbit-context';
import themeConfig from './services/theme';
import Loader from './components/loaders';
import Notification from './layout/notification';
import ErrorBoundary from './components/errorBoundles';
import User from './users';
import Invitations from './users/invitations';
import WechatCode from './users/wechatCode';
import { ShareProductDetailView } from './shares/shareProductDetail';
import RedirectContainer from './users/RedirectContainer';
import UploadDialog from './layout/upload';

// import ActiveUserContainer from './user';
// import Share from './shares';
const ActiveUserContainer = lazy(
  () => import('./user' /* webpackChunkName: "user" */)
);
// import ActiveUserContainer from './user';
const Share = lazy(
  () => import('./shares' /* webpackChunkName: "share" */)
);
const D3DView = lazy(
  ()=>import('./shares/D3DView')
)
const MobilePDF=lazy(() => import('./wechatPDF'));
const MobileDocPDF=lazy(() => import('./wechatDocPDF'));
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      minHeight: '100vh',
      // minWidth: 'fit-content',
      width: '100%',
      backgroundColor: theme.palette.background.default,
      color: theme.palette.getContrastText(
        theme.palette.background.default
      ),
    },
  })
);

const Main = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [user, setUser] = useState(null);

  useEffect(() => {
    // console.log('App启动后，自动上次的登录用户');

    dispatch(resumeLastSessionAction()).then(
      (userData) => {
        userData.logout = logout;
        setUser(userData);
      },
      () => setUser({}));
  }, []);

  const doLogin = (userData) => {
    userData.logout = logout;
    setUser(userData);
  };
  const wechatCodeLogin = useCallback((loginData) => {
    dispatch(wxloginAction(loginData))
    .then(
      (userData) => {
        doLogin(userData);
        window.parent.window.setUser1(userData);
        // location.reload();
        // history.replace('/');
      },
      (err) => {
        window.parent.window.onLoginError(err);
        console.error(err);
      }
    );
  }, [dispatch]);
  const login = useCallback((loginData) => {
    console.log("loginData=======", loginData);
    dispatch(loginAction(loginData))
    .then(
      (userData) => {
        userData.from = loginData.from;
        // 单点登录改造： 手机验证码登录后  修改localstorage中的内容：
        localStorage.setItem('SSOLOGIN', 'false');
        doLogin(userData);
        history.replace('/');
      },
      (err) => {
        console.error(err);
      }
    );
  }, [dispatch]);

  const signup = useCallback((signupData) => {
    dispatch(signupAction(signupData))
    .then(
      (userData) => {
        doLogin(userData);
        history.replace('/');
      },
      (err) => {
        console.error(err);
      });
  }, [dispatch]);

  // 退出登录加个判断，根据tenantID判断是不是ABB团队，跳转到ABB首页
  const logout = useCallback(() => {
    dispatch(logoutAction())
    .then(() => {
      const localTenantId = localStorage.getItem('tenantId');
      const isABBtenant = localTenantId === '20243346-9db5-42bf-a52c-b5d800f5fdd4';
      const env = process.env.NODE_ENV || 'production';
      // 测试地址 https://abbdrives.ivymobi.com.cn
      // 生产地址： https://abbdrives.yangben.cn
      const abbURL = env === 'development' ? 'https://abbdrives.ivymobi.com.cn' : 'https://abbdrives.yangben.cn';
      
      if (isABBtenant) {
        window.location.href = abbURL;
      } else {
        setUser({});
      }
      
    });
  }, [dispatch, setUser]);

  // 创建团队
  const acceptInvitation = useCallback(async (id, data, ) => {
    let res;
    if (user.token) {
      res = await dispatch(acceptInvitationWithLoginedSessionAction({ user, id, data }))
      .catch((e) => console.log('', e));
    } else {
      res = await dispatch(acceptInvitationWithRegisterAction({ id, data }))
      // .then((userData) => { doLogin(userData); })
      .catch((e) => console.log('', e));   //  有问题 code=402来提示错误 没有登录信息  只能卡在当前页面
      isEmpty(res) ? '' : doLogin(res);
    }
    return res;
    // return之后then？
    // .then(() => {
    //   history.replace('/choose');
    // });
  }, [dispatch, user, setUser, logout]);

  if (isNull(user)) {
    return (<Loader />);
  }
  return (
    <div className={classes.root}>
      <CacheSwitch>
        {/* 单点登录跳转页面， 目前只有ABB一个团队有相关功能。怎么做到更通用还需要考虑 */}
        <Route  path={['/SSORedirect/:user', '/SSORedirect']}
          render={() => <RedirectContainer user={user} logout={logout} />}
        />
        <Route path="/user"
          render={() => (user && user.token)
            ? <Redirect to="/" />
            : <User login={login} signup={signup} />
          }
        />
        <Route path="/invitations/:invitationId" exact
          render={() => <Invitations user={user} acceptInvitation={acceptInvitation} />}
        />
        <CacheRoute exact path={['/shares/:id']} >
          <ErrorBoundary>
            <Share user={user} onLogin={doLogin} />
          </ErrorBoundary>
        </CacheRoute>
        <CacheRoute exact path={['/3d']} >
          <ErrorBoundary>
            <D3DView />
          </ErrorBoundary>
        </CacheRoute>
        <CacheRoute exact path={['/mobilePdf/:id']} >
          <ErrorBoundary>
            <MobilePDF />
          </ErrorBoundary>
        </CacheRoute>
        <CacheRoute exact path={['/mobilePdf/:tid/:docid']} >
          <ErrorBoundary>
            <MobileDocPDF />
          </ErrorBoundary>
        </CacheRoute>
        <CacheRoute exact path=  {['/share/ProductDetail/:shareId/:id']} >
          <ShareProductDetailView />
        </CacheRoute>
        {/*扫码登录成功回调*/}
        <Route  path={['/wxlogin']} 
          render={() => <WechatCode user={user} login={wechatCodeLogin} />}
        />
        {!isEmpty(user) &&
          <ActiveUserContainer user={user} logout={logout} />
        }
        <Route>
          <Redirect to={{ pathname: '/user/signin' }}/>
        </Route>
      </CacheSwitch>
    </div>
  );
};

export const ThemeComponents = () => {
  const [mainColor, setMainColor] = useState('#007aff');
  const theme = useMemo(() => {
    themeConfig.palette.primary.main = mainColor;
    return createTheme(themeConfig);
  }, [mainColor]);
  const handleChangeTheme = useCallback((color) => {
    // console.log("8******************************")
    if (color === mainColor) return;
    setMainColor(color);
  }, [mainColor]);
  return (
    <ThemeContext.Provider value={handleChangeTheme}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Loader />} >
          <Main />
          <Notification />
          
        </Suspense>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default function OrbitApp() {
  const store = configureStore(api, {});

  return (
    <BrowserRouter>
      <Provider store={store}>
        <ThemeComponents/>
      </Provider>
    </BrowserRouter>
  );
}
