import React from 'react';
import { CircularProgress } from '@material-ui/core';

export const Downloading = ()=>{
  return <div style={{
    position: 'absolute', top: 0, left: 0,
    height: '100%', width: '100%',
    background: 'rgba(255, 255, 255, .5)'
  }}>
   
    <CircularProgress thickness={4} size={24}
      style={{
        position: 'absolute',
        top: 'calc(50% - 12px)', left: 'calc(50% - 12px)'
      }}
    />
    <div
      style={{
        position: 'absolute',
        color:'#007aff',
        fontSize:'17px',
        top: 'calc(50% + 18px)', left: 'calc(50% - 22px)'
      }}
    >
     {/* 下载中... */}
     </div>
  </div>
}

const Loading = () => (
  <div style={{
    position: 'absolute', top: 0, left: 0,
    height: '100%', width: '100%',
    background: 'rgba(255, 255, 255, .5)'
  }}>
    <CircularProgress thickness={4} size={24}
      style={{
        position: 'absolute',
        top: 'calc(50% - 12px)', left: 'calc(50% - 12px)'
      }}
    />
  </div>
);

export default Loading;
