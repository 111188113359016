import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  IconButton, 
  Input, 
  InputLabel, 
  InputAdornment, 
  FormControl
} from '@material-ui/core';

import { Search, Clear } from '@material-ui/icons';
import { isMobile } from '@ivymobi/orbit-core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      // margin: theme.spacing(1),
      // marginBottom: theme.spacing(2)
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      // width: '25ch',
      '& .MuiInputBase-root': {
        width: '140px',
        transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&.Mui-focused': {
          width: '180px',
        }
      }
    },
  }),
);

export default function SearchInput ({doSearch, doCancel=() => {console.log('111');}}) {
  const classes = useStyles();

  const [value, setValue] = React.useState('');
  const handleChange = (event) => setValue(event.target.value);
  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const userAgent = window.navigator.userAgent;

  return (
    <FormControl className={clsx(classes.margin, classes.textField)} size='small'>
      {/* <InputLabel htmlFor="searchInput">Search</InputLabel> */}
      <Input
        id="searchInput"
        type='text'
        value={value}
        style={{width:isMobile()?'unset':'300px'}}
        onChange={handleChange}
        placeholder='Search'
        endAdornment={
          <InputAdornment position="end">
            {value && <IconButton
              onClick={() => {setValue(''); doCancel();}}
              onMouseDown={handleMouseDown}
              style={{ padding: 0 }}
            >
              <Clear/>
            </IconButton>}
            <IconButton
              onClick={() => doSearch(value)}
              onMouseDown={handleMouseDown}
              style={{ padding: 0 }}
            >
              <Search />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}