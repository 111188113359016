import React from 'react';

export const UserContext = React.createContext(null);

export const TenantContext = React.createContext(null);

export const TeammessageContext = React.createContext(null);

export const LanuageContext = React.createContext(null);

export const OpsInfoContext = React.createContext(null);

export const ProductContext = React.createContext(null);

export const ThemeContext = React.createContext(null);


export const UploadContext = React.createContext(null);

