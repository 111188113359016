import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router';
import { useHistory } from 'react-router';

import {
  Box, Dialog, DialogActions, DialogContentText, DialogTitle, DialogContent, Button
} from '@material-ui/core';
import Loader from '../components/loaders';
// import { User } from '@ivymobi/orbit-core';
import { SSOLoginAction } from '@ivymobi/orbit-core/business/user';
import _ from 'lodash';

// 单点登录重定向逻辑
// 系统跳转到当前页面且通过URL参数可以获取到可识别的登录信息（userObj不为空）
// 先清空原有的用户信息，确保不会互相影响（user不为空的话 执行logout方法， 为空表明没有登录的用户信息直接下一步）
// 然后走一个通过 tenantid 和 email 登录的（针对ABB）的接口， 可以返回用户信息----这一步成功后会出现无法识别通过哪种方式登录的问题。。。

export default function RedirectContainer(props) {
  const { user, logout} = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [noUser, setNoUser] = useState(false);

  const searctStr = location?.search;
  const spliteStr = searctStr.slice(6);
  let decodeStr;
  try {
    decodeStr = decodeURI(spliteStr);
  } catch (e) {
    console.log('errerrrerr', e);
    decodeStr = undefined;
  }
  const userObj = _.isEmpty(decodeStr) ? {} : JSON.parse(decodeStr);
  // const userObj = {
  //   "TNTID": "20243346-9db5-42bf-a52c-b5d800f5fdd4",
  //   "token": 'test123',
  //   "profile": {
  //     "exp": 1694661043,
  //     "iat": 1694660743,
  //     "iss": "https://idp.is.motion.abb.com.cn/realms/ABBMotion",
  //     "aud": "ecatalog-app-fe",
  //     "sub": "b6b957bc-4d33-4599-942d-e00abb21b279",
  //     "typ": "ID",
  //     "session_state": "cb71aa7e-d634-48ea-95dd-573f1bf1ce0d",
  //     "sid": "cb71aa7e-d634-48ea-95dd-573f1bf1ce0d",
  //     "email_verified": true,
  //     "name": "Sofia-Kexin Wang",
  //     "preferred_username": "sofia-kexin.wang@cn.abb.com",
  //     "given_name": "Sofia-Kexin",
  //     "family_name": "Wang",
  //     "email": "sofia-kexin.wang.testtest@cn.abb.com",
      
  //   }
  // };
  // SSOMessage: obj
  //  TNTID: string: tenantid
  //  token: string: sso token
  //  profile: object: sso user message (需要的邮箱信息profile.email)
  const [SSOMessage, setSSOMessage] = useState(userObj);

  useEffect(() => {
    if (_.isEmpty(SSOMessage)) return;
    if (!_.isEmpty(user)) {
      return logout();
    } else {
      dispatch(SSOLoginAction({
        tenantId: SSOMessage.TNTID, 
        email: SSOMessage.profile.email,
        // 用其他邮箱测试
        // email: 'cd@ivymobi.com',
        other_token: SSOMessage.token,
      })).then((res) => {
        // tenantId
        // token
        const resToken = _.get(res, ['token'], '');
        const SSOTNTID = SSOMessage.TNTID;
        const SSOLOGIN = true;
        localStorage.setItem('tenantId', SSOTNTID);
        localStorage.setItem('token', resToken);
        localStorage.setItem('SSOLOGIN', SSOLOGIN);
        history.replace('/documents');
        history.go(0);
        // Location.reload();
      }).catch((err) => {
        console.log('errerrerrerrerrerrerrerr', err);
        // 接口报错默认只提示一个原因，没有该账号
        setNoUser(true);
      });
    }
  }, [dispatch, SSOMessage, user, logout]);

  // 关闭事件，关闭返回ABB首页
  const handleClose = () => {
    // console.log('关闭事件，关闭不关闭弹框');
    console.log('close');
  };
  const env = process.env.NODE_ENV || 'production';
  // 测试地址 https://abbdrives.ivymobi.com.cn
  // 生产地址： https://abbdrives.yangben.cn
  const abbURL = env === 'development' ? 'https://abbdrives.ivymobi.com.cn' : 'https://abbdrives.yangben.cn';
  const handleConfirm = () => {
    window.location.href = abbURL;
  };
  return (
    <>
      <Loader />
      <Dialog
        open={noUser}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"登录失败"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" style={{ color: '#000' }}>
            请联系管理员 jessie-minjie.zhang@cn.abb.com 申请权限
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} color="primary">
            确定
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}