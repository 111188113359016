import * as React from 'react';
import { get } from 'lodash';
import {
  Typography, TypographyProps,
} from '@material-ui/core';

import { useShowContext } from './show';
import Label from './label';

type STextFieldProps<T> = TypographyProps & {
  field: string | ((data: T) => string);
  label?: string;
  id?: string;
  emptyText?: string;
}
export default function STextField<T = unknown>(props: STextFieldProps<T>) {
  const {
    id,
    field, label = (typeof field === 'string' ? field : '-'),field2,stand2,stand1,
    emptyText = '-',
    ...rest
  } = props;

  const data = useShowContext();
  const value = typeof field === 'function'
    ? field(data) || emptyText
    : get(data, field, emptyText);

  const value2 = typeof field === 'function'
    ? field(data) || emptyText
    : get(data, field2, null);

  // console.log({label}, {field}, {value}, {value2}, typeof value === 'string' );

  return (
    <Label id={id} label={label}>
      <Typography {...rest} style={{color: '#000', marginBottom: 0, padding: '2px 0'}}>
        {/* 24.1.11  xx/xx的格式 是两边的值一直是相同的，删了一部分 */}
        {/* {((typeof value === 'string' ? value : JSON.stringify(value))+(stand1?stand1:''))+(value2?('/'+(typeof value2 === 'string' ? value2 : JSON.stringify(value2))+(stand2?stand2:'')):'')} */}
        { typeof value === 'string' ? value : JSON.stringify(value) }
      </Typography>
    </Label>
  );
}