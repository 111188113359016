


import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Box, Breadcrumbs, Button, createStyles, Dialog, DialogContent, Divider, Grid, Link, ListItemIcon, makeStyles, Menu, MenuItem, Tab, Tabs, Theme, Toolbar, Typography, withStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Link as RouteLink, useHistory, useParams } from 'react-router-dom';

// import { getAccountAction, useAccount, } from '@ivymobi/orbit-core/business/tenant';
// import { getStatsAction, useStats } from '@ivymobi/orbit-core/business/stats';
import { getFileType, Tenant } from '@ivymobi/orbit-core';
// import QuickStart from '../dashboard/quickStart';
// import Menus, { OpenMenuItem } from '../components/views/menuItem';
import { getLanguageAction } from '@ivymobi/orbit-core/business/productCenter';
import { filter } from 'lodash';
import '../mitsubishi/products.css';
// import { Favorite, FolderSpecial, Share } from '@material-ui/icons';
import { Loading, TabPanel } from '../components';
import { getRobotProductsDetailAction, useOutProducts } from '@ivymobi/orbit-core/business/user';
import RichText from '../components/richText/RichTextRenderer';
import _ from 'lodash';
import D3DImageView from '../mitsubishi/component/d3dImageView';
import { getUserShareAction } from '@ivymobi/orbit-core/business/share';

const useStyles = makeStyles((theme: Theme) => createStyles({
  pages: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%'
  },
  sliderBar: {
    display: 'flex',
    flexDirection: 'column',
    width: '25%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  content: {
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    // justifyContent:'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },


}));

const getImageUrl = (item, defalut) => {
  if (item?.mime && (item?.mime.length > 0)) {
    let items = filter(item.mime, { mimePurpose: 'detail' });
    if (items.length == 0) {
      items = filter(item.mime, { mimePurpose: 'thumbnail' });
    }
    if (items.length > 0) {
      let img = _.get(items[0], "['mimeSource']");
      return img.length > 0 ? img : defalut;
    }
  }
  return defalut;
}

export const ShareProductDetailView = (props) => {
  const { shareId, id } = useParams<any>() ?? { id: null };
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [share, setShare] = useState(null)
  const _productState = useOutProducts();
  const [extraBreadcrumbs, setExtraBreadcrumbs] = useState([]);
  const [productDetail, setProductDetail] = useState(null);
  const [d3dImages, setD3dImages] = useState([]);
  const [dataSheets, setDataSheets] = useState([]);
  const [showImageUrl, setShowImageUrl] = useState({ show: false, url: '' });
  const [lanuage, setLanguage] = useState(null)
  useEffect(() => {

    dispatch(getUserShareAction({ id: shareId }))
    .then(
      (res) => {
        setShare(res);
        RequestLanguage(res.tenant);
      },

    );
  }, []);
  const RequestLanguage = (_tenant) => {
    dispatch(getLanguageAction({ user: null, tenant: _tenant, local: 'zh' })).then((_language) => {
      setLanguage(_language);
    });
  };
  const get3dUrls = (item) => {

    if (item?.mime && (item?.mime.length > 0)) {
      let images = [];
      let items = filter(item.mime, { mimePurpose: 'detail' });
      for (let i = 0; i < items.length; i++) {
        images.push(items[i].mimeSource[0]['#text'])
      }
      setD3dImages(images);
    }
  }
  const getDataSheet = (item) => {

    let infos = [];
    let datasheets = [];
    let selections = [];
    let controls = [];
    if (item?.mime && (item?.mime.length > 0)) {
      let items = filter(item.mime, { mimePurpose: 'data_sheet' });
      for (let i = 0; i < items.length; i++) {

        datasheets.push({ url: items[i].mimeSource[0]['#text'], name: items[i].mimeAlt[0]['#text'], type: 'dataSheet', to: items[i].mimeSource[0]['#text'] })
      }
    }
    if (item?.productReference.length > 0) {
      let accs = filter(item.productReference, { type: 'accessories' });
      accs.map((item) => {
        let thumbnail = filter(item.mime, { mimePurpose: 'thumbnail' });

        selections.push({ name: item.details?.descriptionShort?.zh, url: thumbnail[0].mimeSource[0]['#text'], to: '/share/ProductDetail/' + shareId + '/' + item.id })
      })
      let contrl = filter(item.productReference, { type: 'others' });
      contrl.map((item) => {
        let thumbnail = filter(item.mime, { mimePurpose: 'thumbnail' });

        controls.push({ name: item.details?.descriptionShort?.zh, url: thumbnail[0].mimeSource[0]['#text'], to: '/share/ProductDetail/' + shareId + '/' + item.id })
      })
    }
    if (datasheets.length > 0) {
      infos.push({ data: datasheets, name: '相关资料' })
    }
    if (controls.length > 0) {
      infos.push({ data: controls, name: '控制器' })
    }
    if (selections.length > 0) {
      infos.push({ data: selections, name: '选件' })
    }
    setDataSheets(infos)
  }
  //查询文档路径
  function findPath(id, paths) {
    const curCat = _productState[id];

    if (!curCat || !curCat.parentId)
      return;

    _productState[curCat.parentId].products?.map((item) => {
      if (item.id === id) {
        const breaks = { id };
        breaks.name = item.cgName?.zh;
        breaks.csname = item.csName;
        paths.push(breaks);
        findPath(curCat.parentId, paths);
      }
    });
  }
  const RequestDetail = () => {
    if (!share)
      return;

    dispatch(getRobotProductsDetailAction({ tenantId: share.tenant.id, id })).then((productDetail) => {
      let result = _.sortBy(productDetail?.richTextInfo, function (item) {
        return item.order;//根据code对数据进行升序排序，若是降序则改成：return -item.code
      });
      let resultPro = _.sortBy(productDetail?.productArticle, function (item) {
        return item.orderIndex;//根据code对数据进行升序排序，若是降序则改成：return -item.code
      });
      productDetail.richTextInfo = result
      productDetail.productArticle = resultPro
      setProductDetail(productDetail);
    });

  };
  useEffect(() => {
    RequestDetail()
  }, [share, id])
  useEffect(() => {
    let test = '';
    Object.keys(_productState).map((key) => {
      _productState[key]?.products?.map((item) => {
        if (item.id === productDetail?.id)
          test = key;
      });


    });
    const paths = [];
    findPath(test, paths);
    paths.reverse();
    paths.push({ id: '', name: productDetail?.details?.descriptionShort?.zh, csname: '' });
    setExtraBreadcrumbs(paths);
    get3dUrls(productDetail);
    getDataSheet(productDetail)

  }, [_productState, productDetail?.id]);
  const productImgs = getImageUrl(productDetail, [{ '#text': '/images/dproduct.svg' }]);
  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Dialog maxWidth='lg'
        open={showImageUrl.show}
        onClose={() => { setShowImageUrl({ ...showImageUrl, show: false }) }}
      >

        <DialogContent>
          <img className='dialogImage' src={showImageUrl.url} ></img>
        </DialogContent>
      </Dialog>
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#2F3941' }}>
        <Typography style={{ color: 'white' }}>{productDetail?.details?.descriptionShort?.zh}</Typography>



      </Toolbar>
      <Box className={classes.pages} style={{ justifyContent: 'center', alignItems: 'center', height: '100%' }}>

        <Box style={{ display: 'flex', width: '100%', justifyContent: 'center', height: '100%', background: 'white' }}>
          <Box style={{ flexGrow: '1', width: '0px', display: 'flex', flexDirection: 'column' }}>
            <Typography style={{ fontSize: '25px', padding: '30px' }}>{productDetail?.details?.descriptionShort?.zh}</Typography>
            <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingBottom: '20px' }}>
              {d3dImages.length > 0 && <D3DImageView images={d3dImages}></D3DImageView>}
              {d3dImages.length > 0 && <img src='/images/360.png' style={{ width: '20px', height: '20px', marginTop: '-20px' }} />}
              {d3dImages.length == 0 && <img src={productImgs[0]['#text']} style={{ objectFit: 'contain', width: '100%', height: '20vw' }}></img>}

            </Box>
            <RelevantBox dataSheets={dataSheets}></RelevantBox>
          </Box>
          <Divider orientation="vertical" variant="middle"></Divider>
          <Box style={{ flexGrow: '1', width: '0px', height: '100%' }}>
            <RichBox richTextInfo={productDetail?.richTextInfo} setShowImageUrl={setShowImageUrl}></RichBox>
          </Box>

        </Box>
      </Box>
    </div>
  );
};
const CustomTabs = withStyles((theme: Theme) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    bottom: 'unset',
    top: '0px',
    height: '4px',
    '& > span': {
      width: '100%',
      backgroundColor: 'red',
    },
  },
  flexContainer: {
    alignItems: 'center',
    padding: '7px'
  }

}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const RelevantTabs = withStyles((theme: Theme) => ({
  root: {
    minHeight: 'unset'
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    bottom: 'unset',
    top: '0px',
    height: '4px',
    '& > span': {

      width: '100%',
      backgroundColor: 'white',
      display: 'none'
    },
  },
  flexContainer: {
    alignItems: 'flex-end',
    padding: '7px',

  }

}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);


const CustomTab = withStyles((theme: Theme) => createStyles({
  root: {
    minWidth: 'unset',
    minHeight: 'unset',
  }
}))(Tab);


const RichBox = (props) => {
  const [value, setValue] = useState(0);
  return (
    <Box style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <CustomTabs value={value} onChange={(event, newValue) => { setValue(newValue); }} aria-label="basic tabs example" >
        {props.richTextInfo?.map((item, i) => <CustomTab label={item.name} style={{ color: value == i ? 'red' : 'black', fontSize: '17px' }} id={`simple-tabpanel-${0}`} />)}
      </CustomTabs>
      <Box style={{ width: '100%', overflowY: 'auto', padding: '20px', height: '0px', flexGrow: 1 }}>
        {props.richTextInfo?.map((item, i) => <TabPanel value={value} id={i} >
          <Box><RichText data={item.info} onClick={(block) => {
            props.setShowImageUrl({ show: true, url: block.src })
          }}></RichText></Box>
        </TabPanel>)}
      </Box>
    </Box>
  )
}
const RelavantView = ({ items }) => {
  const history = useHistory();

  return <Box style={{ display: 'flex', flexDirection: 'column' }}>
    {items.data.map((item) => {

      if (item.type === 'dataSheet') {
        const fileType = getFileType(item.url);
        const icon = fileType === 'photo'
          ? item.url
          : `/fileTypes/${fileType}.svg`;
        item.thunbnail = icon
      } else {
        item.thunbnail = item.url
      }
      return <Box onClick={() => {
        if (item.type === 'dataSheet')
          window.open(item.to)
        else
          history.push(item.to)
      }} style={{ display: 'flex', padding: '8px', borderBottom: '1px solid #e3e3e3' }}>
        <img src={item.thunbnail} style={{ width: '20px', height: '20px' }} />
        <Box style={{ paddingLeft: '5px', cursor: 'pointer' }}>{item.name}</Box>
      </Box>
    })}



  </Box>
}
const RelevantBox = (props) => {
  const [value, setValue] = useState(0);
  return (
    <Box style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
      <RelevantTabs value={value} onChange={(event, newValue) => { setValue(newValue); }} aria-label="basic tabs example" >
        {props.dataSheets?.map((item, i) => <CustomTab label={item.name} style={{ padding: '0px 12px', color: value == i ? 'red' : 'black', fontSize: '15px', borderLeft: value != i ? 'unset' : '1px solid #e3e3e3', borderTop: value != i ? 'unset' : '1px solid #e3e3e3', borderRight: value != i ? 'unset' : '1px solid #e3e3e3', borderBottom: value == i ? 'unset' : '1px solid #e3e3e3' }} id={`simple-tabpanel-${0}`} />)}
        {props.dataSheets?.length > 0 && <Box style={{ width: '100%', borderBottom: '1px solid #e3e3e3' }} id={`simple-tabpanel-${0}`} />}
      </RelevantTabs>
      <Box className='relatives'>
        {props.dataSheets?.map((item, i) => <TabPanel value={value} id={i} >
          <Box>
            <RelavantView items={item}></RelavantView>
            {/* <RichText data={item.info}></RichText> */}
          </Box>
        </TabPanel>)}
      </Box>
    </Box>
  )
}

export default function ShareProductDetailViewContainer() {

  return (
    <ShareProductDetailView
    />
  );
}