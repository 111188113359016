import React, {
  useState
} from 'react';
import { Link, Route, Switch, useParams } from 'react-router-dom';
import {
  InputLabel, MenuItem, FormControl, Select, Box, Button, Typography, TextField, useMediaQuery, useTheme, InputAdornment,
} from '@material-ui/core';
import 'react-phone-number-input/style.css';

import PropTypes from 'prop-types';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { sendLoginMobileCodeAction } from '@ivymobi/orbit-core/business/user';
import { SendSmsCodeButton } from './smsCode';
import { useTranslation } from 'react-i18next';
import { queryParse } from '@ivymobi/orbit-core';

function SigninForm({login, from}) {
  const dispath = useDispatch();
  const { register, handleSubmit, errors, getValues, watch, formState, setValue } = useForm<{
    mobile: string;
    code: string;
    countryCode: string;
    from:string;
  }>({ mode: 'onChange', defaultValues: { mobile: '', code: '', countryCode:'+86', from} });
  const mobile = watch('mobile');
  const [flush, setFlush]=useState(true);
  register({name:'countryCode'});
  if (from)
    register({name:'from'});
  const doSendCode = () => dispath(
    sendLoginMobileCodeAction({ mobile:getValues('mobile'), countryCode:getValues('countryCode')})
  );
  const handleChange = (event) => {
    setValue('countryCode', '+'+event.target.value);
    setFlush(!flush);
    getValues('countryCode').replace('+', '');
    // console.log('======', );
  };

  const CountrySelect = ({ value, onChange, labels, ...rest }) => {
    const [t] = useTranslation();
    return (
      <FormControl fullWidth required >
        <InputLabel id="demo-simple-select-label">{t('login.country')}</InputLabel>
        <Select
          name='countryCode'
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={getValues('countryCode').replace('+', '')}
          label="countryCode"
          onChange={handleChange}
        >
          {getCountries().map((country) => (
            <MenuItem key={country} value={getCountryCallingCode(country)}>{labels[country]}</MenuItem>

          ))}
        </Select>
      </FormControl>
    );};

  CountrySelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    labels: PropTypes.objectOf(PropTypes.string).isRequired
  };
  const [t] = useTranslation();
  console.log('countryCodecountryCode', getValues('countryCode'));
  return (
    <form 
      onSubmit={handleSubmit(login, console.error)}
      style={{ maxWidth: '264px' }}
    >
      <CountrySelect
        labels={en}
        value={getValues('countryCode')}
        onChange={() => {}}
      />
      <TextField 
        label={t('YBT-ADMIN.YBT-ADMIN-030101.YBT-ADMIN-03010107')} 
        placeholder={t('YBT-ADMIN.YBT-ADMIN-030103.YBT-ADMIN-03010320')} 
        fullWidth margin="normal"
        name="mobile" required
        inputRef={register({ required: true, minLength: 8})}
        error={Boolean(errors.mobile)}
        helperText={errors.mobile ? t('YBT-ADMIN.YBT-ADMIN-030103.YBT-ADMIN-03010321') : ''}
        InputProps={{
          startAdornment: <InputAdornment position="start">{getValues('countryCode')}</InputAdornment>,
        }}
      />
      <TextField 
        label={t('share.form.code')} 
        placeholder={t('login.verificationCodePlaceholder')} 
        fullWidth margin="normal"
        name="code" required
        autoComplete="off"
        inputRef={register({ required: true, minLength: 3 })}
        error={Boolean(errors.code)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" style={{ marginBottom: '24px' }}>
              <SendSmsCodeButton
                disabled={Boolean((mobile || '').length === 0 || errors.mobile)}
                doSendCode={doSendCode}
              />
            </InputAdornment>
          )
        }}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        type="submit"
        disabled={!(formState.isValid && formState.isDirty)}
      >
        {t('auth.signin')}
      </Button>
    </form>
  );
}

const SignIn = ({login}) => {
  const [t] = useTranslation();
  const parms = queryParse(window.location.search);

  console.log("parms====", parms);
  return (
    <Box>
      <Logo />
      <SigninForm login={login} from={parms?.from}/>
      <Box p={2} />
      {!parms?.from&&<Box display="flex">
        <Typography variant="subtitle2">
          <Link to="/user/wechat">{t('login.wechat')}</Link>
        </Typography>
      </Box>}
    </Box>
  );
};

function SignupForm({signup}) {
  const dispath = useDispatch();
  const { register, handleSubmit, errors, getValues, watch, formState, setValue} = useForm<{
    mobile: string;
    code: string;
    countryCode: string;
  }>({ mode: 'onChange', defaultValues: { mobile: '', code: '', countryCode:'+86' }});
  const [flush, setFlush]=useState(true);
  register({name:'countryCode'});
  const handleChange = (event) => {
    setValue('countryCode', '+'+event.target.value);
    setFlush(!flush);
  };

  const CountrySelect = ({ value, onChange, labels, ...rest }) => {
    const [t] = useTranslation();
    return (
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{t('login.country')}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name='countryCode'
          value={getValues('countryCode').replace('+', '')}
          label="countryCode"
          onChange={handleChange}
        >
          {getCountries().map((country) => (
            <MenuItem key={country} value={getCountryCallingCode(country)}>{labels[country]}</MenuItem>

          ))}

        </Select>
      </FormControl>
    );
  };

  CountrySelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    labels: PropTypes.objectOf(PropTypes.string).isRequired
  };
  const mobile = watch('mobile');
  const [t] = useTranslation();
  const doSendCode = () => dispath(
    sendLoginMobileCodeAction({ mobile:getValues('mobile'), countryCode:getValues('countryCode') })
  );
  return (
    <form 
      onSubmit={handleSubmit(signup)}
      style={{ maxWidth: '264px' }}
    >
      <CountrySelect
        labels={en}
        value={getValues('countryCode')}
        onChange={() => {}}/>
      <TextField 
        label={t('YBT-ADMIN.YBT-ADMIN-030101.YBT-ADMIN-03010107')} 
        placeholder={t('YBT-ADMIN.YBT-ADMIN-030103.YBT-ADMIN-03010320')} 
        fullWidth 
        margin="normal"
        name="mobile" required
        inputRef={register({ required: true, minLength: 8 })}
        error={Boolean(errors.mobile)}
        helperText={errors.mobile ? t('YBT-ADMIN.YBT-ADMIN-030103.YBT-ADMIN-03010321') : ''}
        InputProps={{
          startAdornment: <InputAdornment position="start">{getValues('countryCode')}</InputAdornment>,
        }}
      />
      <TextField label={t('share.form.code')} placeholder={t('login.verificationCodePlaceholder')} fullWidth margin="normal"
        autoComplete="off"
        name="code" required
        inputRef={register({ required: true })}
        error={Boolean(errors.code)}
        helperText={errors.code?.message || errors.code?.type}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" style={{ marginBottom: '24px' }}>
              <SendSmsCodeButton
                disabled={Boolean((mobile || '').length === 0 || errors.mobile)}
                doSendCode={doSendCode}
              />
            </InputAdornment>
          )
        }}
      />
      <Button 
        variant="contained" 
        color="primary" 
        fullWidth 
        type="submit"
        disabled={!formState.isValid}
      >
        {t('login.freeRegister')}
      </Button>
    </form>
  );
}

function Signup({signup}) {
  const [t] = useTranslation();
  return (
    <Box>
      <Logo />
      <SignupForm signup={signup} />
      <Box p={2} />
      <Box display="flex">
        <Typography variant="subtitle2">
          <Link to="/user/wechat">
            {t('login.wechat')}
          </Link> 
          {t('login.or')} 
          <Link to="/user/signin">
            {t('login.phoneToLogin')}
          </Link>
        </Typography>
      </Box>
    </Box>

  );
}

const Wechat = () => {
  const [t] = useTranslation();
  return (
    <div>
      <Logo />
      <Box p={1} />
      <Typography gutterBottom>{t('login.wechat')}</Typography>
      <img src="/images/wechat.jpg" height={200} width={200} />
      <Box display="flex" pt={1}>
        <Typography variant="subtitle2">
          {t('login.usePhone')} <Link to="/user/signup">{t('auth.signup')}</Link>
        </Typography>
      </Box>
    </div>
  );
};

const Logo = () => {
  const [t] = useTranslation();
  return (
    <div>
      <img
        src="/images/logo.png"
        width={124}
        height="auto"
      />
      <Box p={1} />
      <Typography variant="h6" noWrap>
        {t('login.slogan')}
      </Typography>
    </div>
  )};

const User = ({login, signup}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const bgImageStyle = isSmall
    ? {
      justifyContent: 'center'
    }
    : {
      paddingLeft: '18%',
      backgroundImage: 'url(/images/signup.png)',
      backgroundSize: 'auto 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right',
    };

  const footerStyle = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    lineHeight: '30px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 1)'
  };

  return (
    <Box
      display="flex" 
      width="100%" 
      height="100vh"
      paddingTop="30vh"
      paddingBottom="30px"
      style={bgImageStyle}
    >
      <Switch>
        <Route exact path={['/user/signin', '/user']}>
          <SignIn login={login} />
        </Route>
        <Route exact path="/user/signup">
          <Signup signup={signup} />
        </Route>
        <Route exact path="/user/wechat">
          <Wechat />
        </Route>
      </Switch>
      <Typography variant='body2' style={footerStyle}>京ICP备14008635号-9</Typography>
    </Box>
  );
};

export default User;
