import { filter, get, has, isEmpty, omit, pick, isEqual, isUndefined, lte, union } from 'lodash';
import { useSelector } from 'react-redux';
import { call, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { handleActions, combineActions } from 'redux-actions';
import { sanitize } from '@ivymobi/orbit-domain';
import { AnyAction } from 'redux';

import {
  createPromiseAction,
  dispatch,
  rejectPromiseAction, resolvePromiseAction,
} from '../util/redux-saga-promise';

import { ContextUser, Tenant, TenantActionMeta, Document } from '../types';


export const usePorducts = ({user: {user}, tenant}: TenantActionMeta) => useSelector(
  (state) => get(state, [user.id, 'tenants', tenant.id, 'products'], {})
);

export const useModelOptions = () => useSelector(
  (state) => state
);
/**
 * 获取目录
 * @param payload：目录ID，可选。未设置时，表示获取根文档。  执行了两次？
 */
export const getCatlogsAction = createPromiseAction(
  'product/GET_CATLOGS',
  (data: {tenantId?:string, id?: string, shorturl?: string, nameFilter?: string, catalogCode?: string}) => pick(data, ['tenantId', 'id', 'shorturl', 'nameFilter', 'catalogCode']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

export function* watchGetCatelogs(api) {
  yield takeEvery(getCatlogsAction, function* (action) {
    try {
      const {payload: {tenantId, id, shorturl, nameFilter, catalogCode}, meta: {user: {token, user}, tenant}} = action;
      let url = isEmpty(id) ?
        `productCenter/catalogs?tenantId=${tenantId?tenantId:tenant.id}`
        : `productCenter/catalogs/${id}?tenantId=${tenantId?tenantId:tenant.id}`;
      if (!isEmpty(catalogCode)) {
        // url = url+ `&catalogLan=${catalogCode}`;
        url = url+ '&catalogLan= ' +catalogCode;
      }
      if (shorturl) {
        url = url+`&${shorturl}`+(tenantId?'&numberIndex=1':'');
      }
      if (nameFilter) {
        url = url+'&nameFilter='+nameFilter;
      }
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res, {user, tenant, id});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


export const getProductSelectionCgIDAction = createPromiseAction(
  'product/GET_SELECTION_CGID',
  (data: {}) => pick(data, []),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

export function* watchProductSelectionCgID(api) {
  yield takeEvery(getProductSelectionCgIDAction, function* (action) {
    try {
      const {payload: {}, meta: {user: {token, user}, tenant}} = action;
      let url = `thirdParty/productSelectionCgID?tenantId=${tenant.id}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const getRobotLocalesAction = createPromiseAction(
  'product/GET_LOCALES_ROBOT',
  (data: {local}) => pick(data, ['local']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

export function* watchRobotLocales(api) {
  yield takeEvery(getRobotLocalesAction, function* (action) {
    try {
      const {payload: {local}, meta: {user: {token, user}, tenant}} = action;
      let url = `thirdParty/locales/${local}?tenantId=${tenant.id}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 智能样本 多语言相关
export const getCatalogLanguange = createPromiseAction(
  'product/GET_CATALOG_LANGUAGE',
  (data: { eCatalogType: 'string' }) => pick(data, ['eCatalogType']),
  data => pick(data, ['user', 'tenant'])
);
export function* watchCatlogLanguage(api) {
  yield takeEvery(getCatalogLanguange, function* (action) {
    // console.log("getCatalogLanguangegetCatalogLanguangegetCatalogLanguange-=====", action);
    try {
      const { meta: {user, tenant}, payload: {eCatalogType} } = action;
      
      const res = yield call(api.postJSON, {
        // tenants/getSampleLanguage/b3f097b8-3063-41b6-b957-79258908b98f
        url: `tenants/getSampleLanguage/${tenant.id}?eCatalogType=${eCatalogType}`,
        // token: user.token,
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const getProductSelectionRobotAction = createPromiseAction(
  'product/GET_SELECTION_ROBOT',
  (data: {cgId}) => pick(data, ['cgId']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

export function* watchProductSelectionRobot(api) {
  yield takeEvery(getProductSelectionRobotAction, function* (action) {
    try {
      const {payload: {cgId}, meta: {user: {token, user}, tenant}} = action;
      let url = `thirdParty/${tenant.id}/getProductSelectionRobot?cgId=${cgId}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


export const getLanguageAction = createPromiseAction(
  'product/GET_PRODUCT_LANGUAGE',
  (data: {local?: string}) => pick(data, ['local']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

export function* watchLanguage(api) {
  yield takeEvery(getLanguageAction, function* (action) {
    try {
      const {payload: {local}, meta: {user: {token, user}, tenant}} = action;
      const url = `productCenter/locales/${local}`+'?tenantId='+tenant.id;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res, {user, tenant});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const getUnitsInfoAction = createPromiseAction(
  'product/GET_PRODUCT_UNITS',
  (data: {tenantId?: string}) => pick(data, ['tenantId']),
);

export function* watchUnits(api) {
  yield takeEvery(getUnitsInfoAction, function* (action) {
    try {
      const {payload: {tenantId}} = action;
      const url = `thirdParty/${tenantId}/getUnitsInfo`;
      const res = yield call(api.getJSON, {
        url,
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const getFilterAction = createPromiseAction(
  'product/GET_FILTER_OPTION',
  (data: {id?: string,shorturl?:string,numberIndex?:string}) => pick(data, ['id','shorturl','numberIndex']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

export function* watchFilterOption(api) {
  yield takeEvery(getFilterAction, function* (action) {
    try {
      const {payload: {id, shorturl, numberIndex}, meta: {user: {token, user}, tenant}} = action;
      let url = `productCenter/options/${id}`+'?tenantId='+tenant.id;
      if (shorturl && !numberIndex) {
        url = url+`&${shorturl}&numberIndex=1`;
      } else if (shorturl && numberIndex) {
        url = url+`&${shorturl}&numberIndex=${numberIndex}`;
      }
      const res = yield call(api.getJSON, {
        url,
        token
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res, {user, tenant});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

/**
 * @param payload：根据文档 id 列表获取文档列表
 */
export const getProductListByIds = createPromiseAction(
  'product/listByIds',
  (data) => pick(data, ['ids', 'user', 'tenant']),
  (data) => pick(data, ['user', 'tenant'])
);

function* watchGetProductListByIds() {
  yield takeLatest<AnyAction>(getProductListByIds, function* (action) {
    try {
      const { ids, user, tenant } = action.payload;
      for (const id of ids) {
        yield dispatch(getProductsDetailAction, { id, user, tenant });
      }
      yield resolvePromiseAction(action);
    } catch (e) {
      yield rejectPromiseAction(action, e);
    }
  });
}

export const getProductsDetailAction = createPromiseAction(
  'product/GET_Detail_PRODUCTS',
  (data: {id?: string}) => pick(data, ['id']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

export function* watchProductDetailOption(api) {
  yield takeEvery(getProductsDetailAction, function* (action) {
    try {
      const {payload: {id}, meta: {user: {token, user}, tenant}} = action;
      const url =`productCenter/products/${id}`+'?tenantId='+tenant.id;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res, {user, tenant});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

//智能样本根据url获取团队id
export const getTenantInfoAction = createPromiseAction(
  'product/GET_TENANT_INFO',
  (data: {url?: string}) => pick(data, ['url']),

  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

export function* watchTenantInfo(api) {
  yield takeEvery(getTenantInfoAction, function* (action) {
    try {
      const {payload: {url}} = action;
      const res = yield call(api.postJSON, {
        url:`tenants/selTidByWebLink`,
        body:{url}
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

//新利同创定制接口
export const getXLTCAllCatalogsAction = createPromiseAction(
  'product/GET_ALL_XLTC_CATALOGS',
  (data: {lang?: string, clogCode?: string, type?: string,tenantId?:string}) => pick(data, ['lang', 'clogCode', 'type','tenantId']),
 
);
export function* watchXLTCAllCatalogs(api) {
  yield takeEvery(getXLTCAllCatalogsAction, function* (action) {
    try {
      const {payload: {lang, clogCode, type,tenantId}} = action;
      let url =`productCenter/${tenantId}/allCatalogTree?lang=${lang}&clogCode=${clogCode}&type=${type}`;

      const res = yield call(api.getJSON, {
        url,
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


export const getAllCatalogsAction = createPromiseAction(
  'product/GET_ALL_CATALOGS',
  (data: {id?: string, catalogId?: string, catalogType?: string, catalogLan?: string, languageCode?: string}) => pick(data, ['id', 'catalogId', 'catalogType', 'catalogLan', 'languageCode']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchAllCatalogsOption(api) {
  yield takeEvery(getAllCatalogsAction, function* (action) {
    try {
      const {payload: {id, catalogId, catalogType, catalogLan, languageCode}} = action;
      let url =`productCenter/allCatalogs/${id}`;
      if (catalogId) {
        url = url+`?catalogId=${catalogId}`;
      }
      if (catalogType) {
        url = url+`?catalogType=${catalogType}`;
      }
      if (!isEmpty(languageCode)) {
        url = url+ `?lang=${languageCode}`;
      }
      if (!isEmpty(catalogLan)) {
        url = url+`?catalogLan=${catalogLan}`;
      }
      const res = yield call(api.getJSON, {
        url,
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


export const getThirdTenantInfoAction = createPromiseAction(
  'product/GET_THIRD_TENANT',
  (data: {tenantIndex?: string}) => pick(data, ['tenantIndex']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

export function* watchGetTenantInfo(api) {
  yield takeEvery(getThirdTenantInfoAction, function* (action) {
    try {
      const {payload: {tenantIndex}} = action;
      const url = 'thirdParty/getTenantInfo'+'?tenantIndex='+tenantIndex;
      const res = yield call(api.getJSON, {
        url,
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
//获取控制器
export const getControlsAction = createPromiseAction(
  'product/GET_CONTROLS_TENANT',
  (data: {tenantId?: string,robotId?:string,cgId?:string}) => pick(data, ['tenantId','robotId','cgId']),
);

export function* watchGetControls(api) {
  yield takeEvery(getControlsAction, function* (action) {
    try {
      const {payload: {tenantId,robotId,cgId}} = action;
      const url = `thirdParty/${tenantId}/getProductSelectionController/${robotId}?cgId=${cgId}`;
      const res = yield call(api.getJSON, {
        url,
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

//获取选件
export const getOptionControlsAction = createPromiseAction(
  'product/GET_OPTION_CONTROLS_TENANT',
  (data: {tenantId?: string,robotId?:string,cgIds?:string,controlId?:string}) => pick(data, ['tenantId','robotId','cgIds','controlId']),
);

export function* watchOptionGetControls(api) {
  yield takeEvery(getOptionControlsAction, function* (action) {
    try {
      const {payload: {tenantId,robotId,cgIds,controlId}} = action;
      const url = `thirdParty/${tenantId}/getProductSelectionOption/${robotId}?cgIds=${cgIds}`+(controlId?`&controllerId=${controlId}`:'');
      const res = yield call(api.getJSON, {
        url,
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

//智能化功能
//第一级

export const getFirstIntelligentFunctionAction = createPromiseAction(
  'product/GET_FIRST_Intelligent_LEVEL_CASE',
  (data: Record<string, never> ) => pick(data, []),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchFirstLevelIntelligent(api) {
  yield takeEvery(getFirstIntelligentFunctionAction, function* (action) {
    try {
      const {payload: {}, meta: {user: {token, user}, tenant: {id}}} = action;
      const url = `thirdParty/getFirstIntelligentFunction/${id}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
//智能化第二层
export const getSecondIntelligentFunctionAction = createPromiseAction(
  'product/GET_SECOND_Intelligent_LEVEL_CASE',
  (data: { secondId?:string } ) => pick(data, ['secondId']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchSecondIntelligent(api) {
  yield takeEvery(getSecondIntelligentFunctionAction, function* (action) {
    try {
      const {payload: {secondId}, meta: {user: {token, user}, tenant: {id}}} = action;
      const url = `thirdParty/getSecondIntelligentFunction/${id}/firstLevel/${secondId}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 智能化第三层
export const getThirdIntelligentFunctionAction = createPromiseAction(
  'product/GET_THIRD_LEVEL_CASE',
  (data: { thirdId?:string } ) => pick(data, ['thirdId']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchThirdIntelligent(api) {
  yield takeEvery(getThirdIntelligentFunctionAction, function* (action) {
    try {
      const {payload: {thirdId}, meta: {user: {token, user}, tenant: {id}}} = action;
      const url = `thirdParty/getThirdIntelligentFunction/${id}/secondLevel/${thirdId}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

//变频器行业应用
export const getIndustrialAction = createPromiseAction(
  'product/GET_SV_Industrial',
  (data: Record<string, never> ) => pick(data, []),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchIndustrial(api) {
  yield takeEvery(getIndustrialAction, function* (action) {
    try {
      const {payload: {}, meta: {user: {token, user}, tenant: {id}}} = action;
      const url = `thirdParty/getInverterCases/${id}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
//变频器功能特性
export const getFunctionalAction = createPromiseAction(
  'product/GET_SV_Functional',
  (data: Record<string, never> ) => pick(data, []),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchFunctional(api) {
  yield takeEvery(getFunctionalAction, function* (action) {
    try {
      const {payload: {}, meta: {user: {token, user}, tenant: {id}}} = action;
      const url = `thirdParty/getInverterFunctionCharacteristics/${id}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
//人机界面-特性体验
export const getHMIFunctionalAction = createPromiseAction(
  'product/GET_HMI_Functional',
  (data: Record<string, never> ) => pick(data, []),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchHMIFunctional(api) {
  yield takeEvery(getHMIFunctionalAction, function* (action) {
    try {
      const {payload: {}, meta: {user: {token, user}, tenant: {id}}} = action;
      const url = `thirdParty/getHMIFeatureExperience/${id}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
//放电加工机-操作维护
export const getEDMOperateAction = createPromiseAction(
  'product/GET_EDM_Operate',
  (data: Record<string, never> ) => pick(data, []),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchEDMOperate(api) {
  yield takeEvery(getEDMOperateAction, function* (action) {
    try {
      const {payload: {}, meta: {user: {token, user}, tenant: {id}}} = action;
      const url = `thirdParty/getElectricalDischargeMachineOAM/${id}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
//放电加工机-功能介绍
export const getEDMFunctionIntrolAction = createPromiseAction(
  'product/GET_EDM_FUNCTIONINTROL',
  (data: Record<string, never> ) => pick(data, []),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchEDMFunctionIntorl(api) {
  yield takeEvery(getEDMFunctionIntrolAction, function* (action) {
    try {
      const {payload: {}, meta: {user: {token, user}, tenant: {id}}} = action;
      const url = `thirdParty/getElectricalDischargeMachineFI/${id}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
//放电加工机-加工应用
export const getEDMProcessingAction = createPromiseAction(
  'product/GET_EDM_PROCESSING',
  (data: Record<string, never> ) => pick(data, []),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchEDMProcessing(api) {
  yield takeEvery(getEDMProcessingAction, function* (action) {
    try {
      const {payload: {}, meta: {user: {token, user}, tenant: {id}}} = action;
      const url = `thirdParty/getElectricalDischargeMachinePA/${id}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

//激光加工机-操作维护
export const getLMOperateAction = createPromiseAction(
  'product/GET_LM_Operate',
  (data: Record<string, never> ) => pick(data, []),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchLMOperate(api) {
  yield takeEvery(getLMOperateAction, function* (action) {
    try {
      const {payload: {}, meta: {user: {token, user}, tenant: {id}}} = action;
      const url = `thirdParty/getLaserMachiningOAM/${id}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
//激光加工机-功能介绍
export const getLMFunctionIntrolAction = createPromiseAction(
  'product/GET_LM_FUNCTIONINTROL',
  (data: Record<string, never> ) => pick(data, []),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchLMFunctionIntorl(api) {
  yield takeEvery(getLMFunctionIntrolAction, function* (action) {
    try {
      const {payload: {}, meta: {user: {token, user}, tenant: {id}}} = action;
      const url = `thirdParty/getLaserMachiningFI/${id}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
//激光加工机-加工应用
export const getLMProcessingAction = createPromiseAction(
  'product/GET_LM_PROCESSING',
  (data: Record<string, never> ) => pick(data, []),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchLMProcessing(api) {
  yield takeEvery(getLMProcessingAction, function* (action) {
    try {
      const {payload: {}, meta: {user: {token, user}, tenant: {id}}} = action;
      const url = `thirdParty/getLaserMachiningPA/${id}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


//伺服解决方案
export const getSVSolutionAction = createPromiseAction(
  'product/GET_SV_SOLUTION',
  (data: Record<string, never> ) => pick(data, []),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchSVSolution(api) {
  yield takeEvery(getSVSolutionAction, function* (action) {
    try {
      const {payload: {}, meta: {user: {token, user}, tenant: {id}}} = action;
      const url = `thirdParty/getServoSolutions/${id}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 行业应用案例一级
// getFirstLevelIndustryCase
export const getFirstLevelIndustryCaseAction = createPromiseAction(
  'product/GET_FIRST_LEVEL_CASE',
  (data: Record<string, never> ) => pick(data, []),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchFirstLevelCase(api) {
  yield takeEvery(getFirstLevelIndustryCaseAction, function* (action) {
    try {
      const {payload: {}, meta: {user: {token, user}, tenant: {id}}} = action;
      const url = `thirdParty/getFirstLevelIndustryCase/${id}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 行业应用案例二级
export const getSecondLevelIndustryCaseAction = createPromiseAction(
  'product/GET_SECOND_LEVEL_CASE',
  (data: { secondId?:string } ) => pick(data, ['secondId']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchSecondLevelCase(api) {
  yield takeEvery(getSecondLevelIndustryCaseAction, function* (action) {
    try {
      const {payload: {secondId}, meta: {user: {token, user}, tenant: {id}}} = action;
      const url = `thirdParty/getSecondLevelIndustryCase/${id}/firstLevel/${secondId}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 行业应用案例三级

export const getThirdLevelIndustryAction = createPromiseAction(
  'product/GET_THIRD_Industry_CASE',
  (data: { thirdId?:string } ) => pick(data, ['thirdId']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchThirdLevelCase(api) {
  yield takeEvery(getThirdLevelIndustryAction, function* (action) {
    try {
      const {payload: {thirdId}, meta: {user: {token, user}, tenant: {id}}} = action;
      const url = `thirdParty/getThirdLevelIndustryCase/${id}/secondLevel/${thirdId}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 选取机器人信号
export const getRobotListAction = createPromiseAction(
  'product/GET_ROBOT_LIST',
  (data: { robotIndex?:string }) => pick(data, ['robotIndex']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchRobotList(api) {
  yield takeEvery(getRobotListAction, function*(action) {
    try {
      const {payload: {robotIndex}, meta: {user: { token }}} = action;
      const url = `thirdParty/getRobotList?robotIndex=${robotIndex}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 获取计算结果  api.postJSON   api.getJSON
export const getCalculartorAction = createPromiseAction(
  'product/GET_CALCULATOR_RESULT',
  (data: { calculatorVal?:string, params? }) => pick(data, ['calculatorVal', 'params']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchCalculatorResult(api) {
  yield takeEvery(getCalculartorAction, function*(action) {
    try {
      const {payload: {calculatorVal, params}, meta: {user: { token }}} = action;
      // const url = `thirdParty/${calculatorVal}/getRobotCalculatorResult?tongs_length=${params.tongs_length}&tongs_width=${params.tongs_width}&tongs_weigth=${params.tongs_weigth}&tongs_heart_length=${params.tongs_heart_length}&`+
      // `workpiece_length=${params.workpiece_length}&workpiece_width=${params.workpiece_width}&workpiece_weight=${params.workpiece_weight}&workpiece_heart_length=${params.workpiece_heart_length}`;
      const url = `thirdParty/getRobotCalculatorResult`;
      const res = yield call(api.postJSON, {
        url,
        token,
        body: {...params}
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 获取指定型号的额定计算结果
export const getCalculatorRatedAction = createPromiseAction(
  'product/GET_CALCULATOR_RATED',
  (data: { calculatorVal?:string, params? }) => pick(data, ['calculatorVal', 'params']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchCalculatorRated(api) {
  yield takeEvery(getCalculatorRatedAction, function*(action) {
    try {
      const {payload: {calculatorVal, params}, meta: {user: { token }}} = action;
      // const url = `thirdParty/${calculatorVal}/getRobotCalculatorResult?tongs_length=${params.tongs_length}&tongs_width=${params.tongs_width}&tongs_weigth=${params.tongs_weigth}&tongs_heart_length=${params.tongs_heart_length}&`+
      // `workpiece_length=${params.workpiece_length}&workpiece_width=${params.workpiece_width}&workpiece_weight=${params.workpiece_weight}&workpiece_heart_length=${params.workpiece_heart_length}`;
      const url = `thirdParty/getRobotSpecifiedResult?robotName=${calculatorVal}`;
      const res = yield call(api.getJSON, {
        url,
        token,
        // body: {...params}
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


/********************* 海兴产品中心相关接口 *********************/

// 获取产品中心多语言配置接口
export const getHXLanguageAction = createPromiseAction(
  'product/GET_HX_PRODUCT_LANGUAGE',
  (data: { local?: string }) => pick(data, ['local']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchHXLanguage(api) {
  yield takeEvery(getHXLanguageAction, function* (action) {
    try {
      const { payload: { local }, meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/locales/${local}` + '?tenantId=' + tenant.id;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 获取产品中心指定目录
export const getHXCatalogsAction = createPromiseAction(
  'product/GET_HX_CATALOGS',
  (data: { catalogId?: string, nameFilter?: string }) => pick(data, ['catalogId', 'nameFilter']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchHXCatalogsOption(api) {
  yield takeEvery(getHXCatalogsAction, function* (action) {
    try {
      const {
        meta: { user: { token, user }, tenant },
        payload: { catalogId, nameFilter }
      } = action;
      let url = `productCenter/catalogs`;
      if (catalogId) {
        url = url + `/${catalogId}`;
      }
      if (nameFilter) {
        url = url + `?nameFilter=${nameFilter}`;
      }
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


// chonsole后台 产品管理  -  获取产品详情的接口，  user中有一个相同功能的接口
export const getHXProductsDetailAction = createPromiseAction(
  'product/GET_HXDetail_PRODUCTS',
  (data) => pick(data, ['id', 'language']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchHXProductDetailOption(api) {
  yield takeEvery(getHXProductsDetailAction, function* (action) {
    try {
      const { payload: { id, language }, meta: { user: { token, user }, tenant } } = action;
      let url = `productCenter/products/${id}` + '?tenantId=' + tenant.id;

      if (!isEmpty(language)) {
        url = url + `&language=${language}`;
      }

      // let url = `productCenter/products/${id}` + `?language=${language}` + '&tenantId=' + tenant.id;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

//获取详情页的产品型号
export const getHXProductsSKUAction = createPromiseAction(
  'product/GET_HXDetail_SPU_DetailInfo',
  (data: { id?: string, language: string, spid:string, sorted:any }) => pick(data, ['id', 'language','spid','sorted']),
  (data: TenantActionMeta) => pick(data, ['tenant'])
);
export function* watchHXProductSKUAction(api) {
  yield takeEvery(getHXProductsSKUAction, function* (action) {
    try {
      const { 
        payload: { id, language, spid, sorted }, 
        meta: { 
          tenant 
        } 
      } = action;
      const url = `productCenter/getProductModel/${id}?language=${language}&tenantId=${tenant.id}&spid=${spid}`;
      const res = yield call(api.postJSON, {  
        url,
        body:{sorted}
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res, {tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// ------------------23.11.10--------------------
// 修改单个SPU
export const updateOneSPUHeaderAction = createPromiseAction(
  'product/UPDATE_ONE_SPU_HEADER',
  (data: { id?: string, spid, features, title }) => pick(data, ['id', 'spid', 'features', 'title']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchUpdateOneSPUHeaderAction(api) {
  yield takeEvery(updateOneSPUHeaderAction, function* (action) {
    try {
      const { payload: { id, spid, features, title }, meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/saveProductModelTableHeader/${id}?tenantId=${tenant.id}&spid=${spid}`;
      const res = yield call(api.postJSON, {
        url,
        token,
        body: {features, title}
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 修改同系列所有SPU
export const updateAllSPUHeaderAction = createPromiseAction(
  'product/UPDATE_ALL_SPU_HEADER',
  (data: { id?: string, features, title }) => pick(data, ['id', 'features', 'title']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchUpdateAllSPUHeaderAction(api) {
  yield takeEvery(updateAllSPUHeaderAction, function* (action) {
    try {
      const { payload: { id, features, title }, meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/saveCatalogModelTableHeader/${id}?tenantId=${tenant.id}`;
      const res = yield call(api.postJSON, {
        url,
        token,
        body: {features, title}
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// -----------end 23.11.10-----------------------




//获取详情页的产品型号 表头信息
export const getHXProductsSKUTableHeaderAction = createPromiseAction(
  'product/GET_HXDetail_SPU_DetailInfo_TABLE_HEADER',
  (data: { id?: string, language: string,spid:string,sorted:any }) => pick(data, ['id', 'language', 'spid', 'sorted']),
  (data: TenantActionMeta) => pick(data, ['tenant'])
);
export function* watchHXProductSKUTableHeaderAction(api) {
  yield takeEvery(getHXProductsSKUTableHeaderAction, function* (action) {
    try {
      const { 
        payload: { id, language, spid, sorted }, 
        meta: { 
          tenant 
        } 
      } = action;
      const url = `productCenter/getProductModelTableHeader/${id}?language=${language}&tenantId=${tenant.id}&spid=${spid}`;
      const res = yield call(api.postJSON, {  
        url,
        body:{sorted}
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res, {tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

//获取详情页的简介信息
export const getHXProductsDetailInfoAction = createPromiseAction(
  'product/GET_HXDetail_PRODUCTS_DetailInfo',
  (data: { id?: string, language: string }) => pick(data, ['id', 'language']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchHXProductDetailInfoAction(api) {
  yield takeEvery(getHXProductsDetailInfoAction, function* (action) {
    try {
      const { 
        payload: { id, language }, 
        meta: { 
          user: { token, user }, 
          tenant 
        } 
      } = action;
      const url = `productCenter/${tenant.id}/getProductIllustrate/${id}?language=${language}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
//树结构搜索

export const getHXSearchCatalogs = createPromiseAction(
  'product/GET_HX_SEARCH_CATALOGS',
  (data: { text?: string}) => pick(data, ['text']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchSearchCatalogs(api) {
  yield takeEvery(getHXSearchCatalogs, function* (action) {
    try {
      const {payload:{text}, meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/oneLevelCatalogs/${tenant.id}?nameFilter=${text}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

//获取产品中心整个树结构信息
export const getHXAllCatalogs = createPromiseAction(
  'product/GET_HX_ALL_CATALOGS',
  (data) => pick(data, ['languageCode']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchAllCatalogs(api) {
  yield takeEvery(getHXAllCatalogs, function* (action) {
    try {
      const { payload: { languageCode }, meta: { user: { token, user }, tenant } } = action;
      let url = `productCenter/allCatalogs/${tenant.id}`;
      url = !isEmpty(languageCode) ? url += `?lang=${languageCode}` : url;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


//文章管理
//***********************************/

//获取产品中心整个树结构信息
export const getArticles = createPromiseAction(
  'product/GET_ARTICELS',
  (data: { id?: string, test?: string}) => pick(data, ['id', 'test']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchArticels(api) {
  yield takeEvery(getArticles, function* (action) {
    try {
      const {payload:{id, test}, meta: { user: { token, user }, tenant } } = action;
      const url = `tenants/${tenant.id}/selArticle`;
      // debugger
      const res = yield call(api.postJSON, {
        url,
        token,
        body:{
          parentId:id??null
        }
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 根据文章 id 查询文章
export const getArticlesById = createPromiseAction(
  'product/GET_ARTICELS_BYID',
  (data: { id?: string, type?: string}) => pick(data, ['id','type']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchArticelById(api) {
  yield takeEvery(getArticlesById, function* (action) {
    try {
      const {payload:{id, type}, meta: { user: { token, user }, tenant } } = action;
      const url = `tenants/${tenant.id}/${type??'1'}/${id}/selArticleById`;
      const res = yield call(api.getJSON, {
        url,
        token,
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 获取产品列表
export const getProductByTid = createPromiseAction(
  'product/GET_PRODUCT_BYTID',
  (data: { tid?: string }) => pick(data, ['tid']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchProductByTid(api) {
  yield takeEvery(getProductByTid, function* (action) {
    try {
      const {payload:{ tid }, meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/${tid}/selProductByTid`;
      const res = yield call(api.getJSON, {
        url
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


//插入目录或者文章
export const postCateOrArticel = createPromiseAction(
  'product/POST_CATE_ARTICEL',
  (data: { aName?: string,thumbnail?: string,parentId?: string,info?: string,aType?: string,productIds?:unknown}) => pick(data, ['aName','thumbnail','parentId','aType','info','productIds']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchCateOrArticel(api) {
  yield takeEvery(postCateOrArticel, function* (action) {
    try {
      const {payload, meta: { user: { token, user }, tenant } } = action;
      const url = `tenants/${tenant.id}/insertArticle`;
      const res = yield call(api.postJSON, {
        url,
        token,
        body:payload
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const updateCateOrArticel = createPromiseAction(
  'product/UPDATE_CATE_ARTICEL',
  (data: {id?:string, aName?: string,thumbnail?: string,parentId?: string,info?: string,aType?: string,productIds?:unknown}) => pick(data, ['id','aName','thumbnail','parentId','aType','info','productIds']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchUpdateCateOrArticel(api) {
  yield takeEvery(updateCateOrArticel, function* (action) {
    try {
      const {payload, meta: { user: { token, user }, tenant } } = action;
      const url = `tenants/${tenant.id}/${payload.id}/updateArticle`;
      const res = yield call(api.postJSON, {
        url,
        token,
        body:payload
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
export const deleteCateOrArticel = createPromiseAction(
  'product/DELETE_CATE_ARTICEL',
  (data: {id?:string,parentId?: string}) => pick(data, ['id','parentId']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchDeleteCateOrArticel(api) {
  yield takeEvery(deleteCateOrArticel, function* (action) {
    try {
      const {payload, meta: { user: { token, user }, tenant } } = action;
      const url = `tenants/${tenant.id}/${payload.id}/delArticle`;
      const res = yield call(api.deleteJSON, {
        url,
        token,
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
export const changeArticles = createPromiseAction(
  'product/CHANGE_ORDER_CATE_ARTICEL',
  (data: {childIds?:unknown,id?: string}) => pick(data, ['childIds','id']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchChangeCateOrArticel(api) {
  yield takeEvery(changeArticles, function* (action) {
    try {
      const {payload, meta: { user: { token, user }, tenant } } = action;
      let  url ='';
      if(payload.id==null){
        url = `tenants/${tenant.id}/moveRootDirectory`;
      }else{
        url = `tenants/${tenant.id}/${payload.id}/moveArticle`;

      }
      const res = yield call(api.postJSON, {
        url,
        token,
        body:{
          childIds:payload.childIds
        }
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

/************************************ */


// 获取左侧筛选项接口
export const getHXFilterAction = createPromiseAction(
  'product/GET_HX_FILTER_OPTION',
  (data: { id?: string, nameFilter?: string }) => pick(data, ['id', 'nameFilter']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchHXFilterOption(api) {
  yield takeEvery(getHXFilterAction, function* (action) {
    try {
      const { payload: { id, nameFilter }, meta: { user: { token, user }, tenant } } = action;
      var url = `productCenter/options/${id}`;
      if (nameFilter) {
        url = url + `?${nameFilter}`;
      }
      const res = yield call(api.getJSON, {
        url,
        token
      });
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

/********************* 海兴产品中心 Console 接口 *********************/

// 产品管理拖拽进行排序
export const changeHXProductCenterOrderAction = createPromiseAction(
  'product/UPDATE_HX_PRODUCT_ORDER_MANAGE',
  (data: { id?: string, params?}) => pick(data, ['id', 'params']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchHXProductCenterChangeOrder(api) {
  yield takeEvery(changeHXProductCenterOrderAction, function* (action) {
    try {
      const { payload: { id, params }, meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/${tenant.id}/changePCOrder`;
      const res = yield call(api.postJSON, {
        url,
        token,
        body: { ...params }
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 添加或修改产品简要说明
export const updateHXProducteIntroAction = createPromiseAction(
  'product/UPDATE_HX_PRODUCT_INTRO',
  (data: { id?: string, textInfo?: string, language?: string }) => pick(data, ['id', 'textInfo', 'language']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchUpdateHXProductIntro(api) {
  yield takeEvery(updateHXProducteIntroAction, function* (action) {
    try {
      const { payload: { id, textInfo, language }, meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/${tenant.id}/updateProductIllustrate/${id}`;
      const res = yield call(api.postJSON, {
        url,
        token,
        body: { 
          textInfo: textInfo,
          language,
        }
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 获取筛选管理列表(包括左侧共有属性和右侧已选择)
export const getHXFilterManageListAction = createPromiseAction(
  'product/GET_HX_FILTER_MANAGE_LIST',
  (data: { id?: string }) => pick(data, ['id']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchHXFilterManageList(api) {
  yield takeEvery(getHXFilterManageListAction, function* (action) {
    try {
      const { payload: { id }, meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/${tenant.id}/getFilterManageList/${id}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 更新筛选管理的数据
export const updateHXFilterManageAction = createPromiseAction(
  'product/UPDATE_HX_FILTER_MANAGE',
  (data: { id?: string, params?}) => pick(data, ['id', 'params']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchUpdateHXFilterManage(api) {
  yield takeEvery(updateHXFilterManageAction, function* (action) {
    try {
      const { payload: { id, params }, meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/${tenant.id}/updateFilterManager/${id}`;
      const res = yield call(api.postJSON, {
        url,
        token,
        body: { ...params }
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 获取排序管理信息
export const getHXOrderManageAction = createPromiseAction(
  'product/GET_HX_ORDER_MANAGE_INFO',
  (data: {}) => pick(data, []),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchHXOrderManageInfo(api) {
  yield takeEvery(getHXOrderManageAction, function* (action) {
    try {
      const { meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/${tenant.id}/getOrderManageInfo`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 更新排序管理数据
export const updateHXOrderManageAction = createPromiseAction(
  'product/UPDATE_HX_ORDER_MANAGE',
  (data: { params?}) => pick(data, ['params']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchUpdateHXOrderManage(api) {
  yield takeEvery(updateHXOrderManageAction, function* (action) {
    try {
      const { payload: { params }, meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/${tenant.id}/updateOrderManageInfo`;
      const res = yield call(api.postJSON, {
        url,
        token,
        body: { ...params }
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 获取产品列表字段管理
export const getHXProductsFieldManageAction = createPromiseAction(
  'product/GET_HX_PRODUCTS_FIELD_MANAGE_INFO',
  (data: {}) => pick(data, []),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchHXProductsFieldManage(api) {
  yield takeEvery(getHXProductsFieldManageAction, function* (action) {
    try {
      const { meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/${tenant.id}/getProductsFieldManager`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 更新产品列表字段管理
export const updateHXProductsFieldManageAction = createPromiseAction(
  'product/UPDATE_HX_PRODUCTS_FIELD_MANAGE',
  (data: { params?}) => pick(data, ['params']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchUpdateHXProductsFieldManage(api) {
  yield takeEvery(updateHXProductsFieldManageAction, function* (action) {
    try {
      const { payload: { params }, meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/${tenant.id}/updateProductFieldManager`; // updateProductFieldManager
      const res = yield call(api.postJSON, {
        url,
        token,
        body: { ...params }
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 获取产品搜索关键字管理数据
export const getHXSearchKeywordsManageAction = createPromiseAction(
  'product/GET_HX_SEARCH_KEYWORDS_MANAGE',
  (data: {}) => pick(data, []),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchHXSearchKeywordsManage(api) {
  yield takeEvery(getHXSearchKeywordsManageAction, function* (action) {
    try {
      const { meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/${tenant.id}/getSearchKeywordsManager`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 保存产品搜索关键字
export const updateHXSearchKeywordsManageAction = createPromiseAction(
  'product/UPDATE_HX_SEARCH_KEYWORDS_MANAGE',
  (data: { params?}) => pick(data, ['params']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchUpdateHXSearchKeywordsManage(api) {
  yield takeEvery(updateHXSearchKeywordsManageAction, function* (action) {
    try {
      const { payload: { params }, meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/${tenant.id}/updateProductSearchKeywordsManager`;
      const res = yield call(api.postJSON, {
        url,
        token,
        body: { ...params }
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 获取产品详情技术参数等面包屑
export const getHXProductArticleInfoAction = createPromiseAction(
  'product/GET_HX_PRODUCT_ARTICLE_INFO',
  (data: { id?: string }) => pick(data, ['id']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchHXProductArticleInfo(api) {
  yield takeEvery(getHXProductArticleInfoAction, function* (action) {
    try {
      const { payload: { id }, meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/${tenant.id}/getProductArticleInfo/${id}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 移动产品详情技术参数面包屑
export const moveHXProductArticle = createPromiseAction(
  'product/MOVE_HX_PRODUCT_ARTICLE',
  (data: { id?: string, params?}) => pick(data, ['id', 'params']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchMoveHXProductArticle(api) {
  yield takeEvery(moveHXProductArticle, function* (action) {
    try {
      const { payload: { id, params }, meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/${tenant.id}/moveProductArticle/${id}`;
      const res = yield call(api.postJSON, {
        url,
        token,
        body: { ...params }
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 禁用/启用文章（url里是文章ID）
export const disableHXArticle = createPromiseAction(
  'product/DISABLE_HX_ARTICLE',
  (data: { id?: string }) => pick(data, ['id']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchDisableHXArticle(api) {
  yield takeEvery(disableHXArticle, function* (action) {
    try {
      const { payload: { id }, meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/${tenant.id}/disableArticle/${id}/disable`;
      const res = yield call(api.postJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 禁用/启用文章（url里是文章ID）
export const ableHXArticle = createPromiseAction(
  'product/ABLE_HX_ARTICLE',
  (data: { id?: string }) => pick(data, ['id']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchAbleHXArticle(api) {
  yield takeEvery(ableHXArticle, function* (action) { 
    try {
      const { payload: { id }, meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/${tenant.id}/disableArticle/${id}/enable`;
      const res = yield call(api.postJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 添加或修改文章
export const updateHXProductArticleAction = createPromiseAction(
  'product/UPDATE_HX_PRODUCT_ARTICLE',
  (data: { id?: string, params }) => pick(data, ['id', 'params']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchUpdateHXProductArticle(api) {
  yield takeEvery(updateHXProductArticleAction, function* (action) {
    try {
      const { payload: { id, params }, meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/${tenant.id}/uploadProductArticle/${id}`;
      const res = yield call(api.postJSON, {
        url,
        token,
        body: { ...params }
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 删除文章 productCenter/deleteProductArticle/id
export const deleteHXProductArticleAction = createPromiseAction(
  'product/DELETE_HX_PRODUCT_ARTICLE',
  (data: { id?: string }) => pick(data, ['id']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchDeleteHXProductArticle(api) {
  yield takeEvery(deleteHXProductArticleAction, function* (action) {
    try {
      const { payload: { id }, meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/${tenant.id}/deleteProductArticle/${id}`;
      const res = yield call(api.deleteJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 修改技术参数 productCenter/updateTechnicalParams/id
export const updateHXProductParamsAction = createPromiseAction(
  'product/UPDATE_HX_PRODUCT_PARAMS',
  (data: { id?: string, params }) => pick(data, ['id', 'params']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchUpdateHXProductParams(api) {
  yield takeEvery(updateHXProductParamsAction, function* (action) {
    try {
      const { payload: { id, params }, meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/${tenant.id}/updateTechnicalParams/${id}`;
      const res = yield call(api.postJSON, {
        url,
        token,
        body: { ...params }
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 修改相关资料（貌似只能改标题） productCenter/updateRelatedData/id
export const updateHXProductRelatedDataAction = createPromiseAction(
  'product/UPDATE_HX_PRODUCT_RELATED_DATA',
  (data: { id?: string, params }) => pick(data, ['id', 'params']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchUpdateHXProductRelatedData(api) {
  yield takeEvery(updateHXProductRelatedDataAction, function* (action) {
    try {
      const { payload: { id, params }, meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/${tenant.id}/updateRelatedData/${id}`;
      const res = yield call(api.postJSON, {
        url,
        token,
        body: { ...params }
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 修改相关产品（貌似只能改标题） productCenter/updateRelatedProducts/id
export const updateHXProductRelatedProductsAction = createPromiseAction(
  'product/UPDATE_HX_PRODUCT_RELATED_PRODUCTS',
  (data: { id?: string, params }) => pick(data, ['id', 'params']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchUpdateHXProductRelatedProducts(api) {
  yield takeEvery(updateHXProductRelatedProductsAction, function* (action) {
    try {
      const { payload: { id, params }, meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/${tenant.id}/updateRelatedProducts/${id}`;
      const res = yield call(api.postJSON, {
        url,
        token,
        body: { ...params }
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 富文本添加图片接口
export const uploadHXRichTextImageAction = createPromiseAction(
  'product/_HX_RICH_TEXT_IMAGE',
  (data: { file }) => pick(data, ['file']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
function* watchUploadHXRichTextImage(api) {
  yield takeEvery(uploadHXRichTextImageAction, function* (action) {
    try {
      const { payload: { file }, meta: { user: { token, user }, tenant } } = action;
      const res = yield call(api.upload, {
        url: 'tenants/assets',
        token: token,
        tenantId: tenant.id,
        file: file,
      });
      yield call(resolvePromiseAction, action, [res?.[0]?.url]);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


// 更新catalog 与语言的对应关系
export const bindCatalogCodeAndLanguage = createPromiseAction(
  'product/BIND_CATALOG_CODE_AND_LANGUAGE',
  (data: { params }) => pick(data, ['params']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchBindCodeAndLanguage(api) {
  yield takeEvery(bindCatalogCodeAndLanguage, function* (action) {
    try {
      const { payload: {  params }, meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/${tenant.id}/updateCatalogCodeToLanguageTable`;
      const res = yield call(api.postJSON, {
        url,
        token,
        body: { ...params }
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 查询catalog 关联状态
export const getStatusCatalogCodeAndLanguage = createPromiseAction(
  'product/GETSTATUS_CATALOG_CODE_AND_LANGUAGE',
  (data: { lang }) => pick(data, ['lang']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchGetStatusCodeAndLanguage(api) {
  yield takeEvery(getStatusCatalogCodeAndLanguage, function* (action) {
    try {
      const { payload: { lang }, meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/${tenant.id}/${lang}/selCatalogCodeByLanguageTable`;
      const res = yield call(api.getJSON, {
        url,
        token,
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// getCatalogsCode
export const getCatalogsCode = createPromiseAction(
  'product/GET_CATALOGS_CODE',
  (data: {}) => pick(data, []),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchGetCatalogCode(api) {
  yield takeEvery(getCatalogsCode, function* (action) {
    try {
      const { meta: { user: { token, user }, tenant } } = action;
      const url = `productCenter/${tenant.id}/getCatalogsCode`;
      const res = yield call(api.getJSON, {
        url,
        token,
      });
      yield call(resolvePromiseAction, action, res, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


// 导出PDF文件
export const exportPDFfile = createPromiseAction(
  'product/EXPORT_PDF_FILE',
  (data: { params?}) => pick(data, ['params']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
export function* watchExportPDFfiles(api) {
  yield takeEvery(exportPDFfile, function* (action) {
    try {
      const { payload: { params }, meta: { user: { token, user }, tenant } } = action;
      // const url = `tenants/${tenant.id}/product/${params.id}/exportProductToPdf?language=${params.Language}&template=${params.template}`; // updateProductFieldManager
      // const res = yield call(api.downloadPDFFile, {

      // downloadPDFByPost
      const url = `tenants/${tenant.id}/product/${params.id}/exportProductToPdf`; // updateProductFieldManager
      const res = yield call(api.downloadPDFByPost, {
        url,
        token,
        fileName: params.fileName,
        body: { 
          Language: params.Language,
          template: params.template,
          productFeature: {
            features: params.features,
            ftgroup: params.ftgroup,
          }
        }
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}



export const reducer = handleActions({
  [combineActions(getCatlogsAction.resolved)]: (state, action) => {
    const docs = action.payload;
    //检测是否存在上机
    let parentId = 'top';
   
    Object.keys(state).map((key) => {
      state[key].products?.map((item) => {
        if (item.id === action.meta.id)
          parentId = key;
      });
      

    });
    const _allcats = filter(docs.catalogs, (item) => {
      item.parentId = parentId;
      if (item.csIndex==='0')
        return false;
      return true;
    });
   
    const parentDoc = state[parentId] ?? {};
    return {
      ...state,
      
      [parentId]: {
        ...parentDoc,
        documents: !isUndefined(action.meta.id)?[...(parentDoc.documents ?? []), action.meta.id]:[...(parentDoc.documents ?? [])]
      },
      [!isUndefined(action.meta.id)?action.meta.id:parentId]: {...state[!isUndefined(action.meta.id)?action.meta.id:parentId], parentId :!isUndefined(action.meta.id)?parentId:null, ['products']:union(_allcats, docs.productsData)}
    };
  },
  [combineActions(getProductsDetailAction.resolved)]: (state, action) => {
    const doc = action.payload;
    // console.log("doc===",doc)
    return {
      ...state,
      [doc.id]: doc    };
  },
}, {});
export const watchers = [
  watchGetCatelogs,
  watchLanguage,
  watchFilterOption,
  watchProductDetailOption,
  watchAllCatalogsOption,
  watchXLTCAllCatalogs,
  watchTenantInfo,
 
  watchGetTenantInfo,
  watchProductSelectionCgID,
  watchProductSelectionRobot,
  watchRobotLocales,

  watchGetProductListByIds,
  watchHXLanguage,
  watchAllCatalogs,
  watchSearchCatalogs,
  watchHXCatalogsOption,
  watchHXFilterOption,
  watchHXProductDetailOption,
  watchHXProductCenterChangeOrder,
  watchUpdateHXProductIntro,
  watchHXFilterManageList,
  watchUpdateHXFilterManage,
  watchHXOrderManageInfo,
  watchUpdateHXOrderManage,
  watchHXProductsFieldManage,
  watchUpdateHXProductsFieldManage,
  watchHXSearchKeywordsManage,
  watchUpdateHXSearchKeywordsManage,
  watchHXProductDetailInfoAction,

  watchHXProductArticleInfo,
  watchMoveHXProductArticle,
  watchUpdateHXProductArticle,
  watchDeleteHXProductArticle,
  watchUpdateHXProductParams,
  watchUpdateHXProductRelatedData,
  watchUpdateHXProductRelatedProducts,
  watchUploadHXRichTextImage,
  watchDisableHXArticle,
  watchAbleHXArticle,
  //产品管理
  watchArticels,
  watchCateOrArticel,
  watchUpdateCateOrArticel, watchDeleteCateOrArticel,
  watchChangeCateOrArticel,
  watchCatlogLanguage,
  watchBindCodeAndLanguage,
  watchGetStatusCodeAndLanguage,
  watchUpdateOneSPUHeaderAction,
  watchUpdateAllSPUHeaderAction,
  watchGetCatalogCode,
  watchExportPDFfiles,
];
