import React from 'react';
import {
  Box, Card, Grid,
  Typography, Hidden
} from '@material-ui/core';
import withWidth, {WithWidth} from '@material-ui/core/withWidth';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import RoomIcon from '@material-ui/icons/Room';


const VCard = withWidth() ((props) => {
  const {
    width,
    // dense = false,
    cardWidth = '100%',
    avatar, name, status,
    department, title,
    phone, email = '您的邮箱',
    logo = '/images/logo.png',
    tenantName, address,
  } = props;
  const dense = (width === 'xs' || width === 'sm');
  // console.log(props);
  const imageURL = status === '未认证' ? '/images/n_certified.png' : '/images/certified.png';
  return (
    <Card
      style={{ 
        position: 'relative', 
        height: 0, 
        paddingBottom: '60%', 
        width: '100%',
        // minWidth: '400px',
        borderRadius: 16 
      }}
      elevation={4}
    >
      <Grid container style={{ height: '100%', position: 'absolute' }}>
        <Grid item xs={5} style={{
          height: '100%',
          backgroundImage: `url(${avatar || '/images/honeycomb.svg'})`,
          backgroundSize: avatar ? 'cover' : 'auto',
          backgroundPosition: 'center',
          clipPath: 'polygon(0% 0%, 100% 0%, 65% 100%, 0% 100%)',
        }} />
        <Grid item xs={7}
          style={{
            height: '100%',
            padding: dense ? '8px' : '16px',
            display: 'flex', flexDirection: 'column'
          }}
        >
          <Box display='flex' alignItems='center' flexDirection='row' justifyContent='flex-end' mb={dense ? 0 : 1.5}>
            <Box height={dense ? '36px' : '48px'} width={dense ? '56px' : '72px'}
              mr={1}
              style={{ background: `url(${logo}) no-repeat left`, backgroundSize: 'contain' }}
            />
           
            {/* <Box height={dense ? '24px' : '26px'} width={dense ? '24px' : '26px'}
              style={{ background: `url(${imageURL}) no-repeat left`, backgroundSize: 'contain' }}
            /> */}
          </Box>
          <Box >
            <Typography variant='h5' >
              {dense ? name : <strong>{name}</strong>}
            </Typography>
            <Typography variant='overline'>
              {[department, title].filter(i => i).join(' / ')}
            </Typography>
          </Box>
          <Box flex={1} />
          <Typography  style={{fontSize: dense ? 'x-small' : 'small'}}>{tenantName}</Typography>
          {phone && <Typography gutterBottom={!dense} style={{ fontSize: dense ? 'x-small' : 'small', fontWeight: 400 }} >
            <PhoneIcon style={{ fontSize: 'smaller', marginRight: '8px' }} />
            {phone}
          </Typography>}
          {email && <Typography gutterBottom={!dense} style={{ fontSize: dense ? 'x-small' : 'small', fontWeight: 400 }} >
            <EmailIcon style={{ fontSize: 'smaller', marginRight: '8px' }} />
            {email}
          </Typography>}
          {address && <Typography gutterBottom={!dense} style={{ fontSize: dense ? 'x-small' : 'small', fontWeight: 400 }} >
            <RoomIcon style={{ fontSize: 'smaller', marginRight: '8px' }} />
            {address}
          </Typography>}
        </Grid>
      </Grid>
    </Card>
  );
});
export default VCard;