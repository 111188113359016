import React, {useEffect} from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

interface SaveButtonProps extends ButtonProps {
  label?: string;
}

export default function SaveButtonValid(props: SaveButtonProps) {
  var [t] = useTranslation();
  const { label =  t('YBT-ADMIN.YBT-ADMIN-040402.YBT-ADMIN-04040208'),
    startIcon = <Save />,
    ...restButtonProps
  } = props;

  const {
    formState: { isDirty, isSubmitting, isValid, errors },
    watch, trigger
  } = useFormContext();
  const watchForm = watch();

  const disabled = !isValid;
  const icon = isSubmitting
    ? <CircularProgress size={18} thickness={2} color="inherit" />
    : startIcon;

  return (
    <Button
      type="submit"
      variant="contained" 
      color="primary"
      disabled={disabled}
      {...restButtonProps}
    >
      {label}
    </Button>
  );
}
