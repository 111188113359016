import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { isUndefined, isNull } from 'lodash';

const localLanguage = !isUndefined(localStorage.getItem('i18nextLng')) ?
  !isNull(localStorage.getItem('i18nextLng')) ?
    localStorage.getItem('i18nextLng') 
    : 'zh'
  : 'zh';


i18n
// load translation using http
// learn more: https://github.com/i18next/i18next-http-backend
.use(Backend)
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
.use(LanguageDetector)
// pass the i18n instance to react-i18next.
.use(initReactI18next)
// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
.init({
  supportedLngs: ['zh', 'en'],
  // lng: 'zh',
  // fallbackLng: 'zh',
  lng: `${localLanguage}`,
  fallbackLng: `${localLanguage}`,
  debug: false,
  load: 'languageOnly',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  backend: {
    customHeaders: {
      'Cache-Control': 'max-age=0'
    }
  },
  detection: {
    order: ['querystring', 'localStorage', 'navigator'],
    caches: ['localStorage']
  }
});

export default i18n;
