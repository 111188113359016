

import { Box } from '@material-ui/core';
import axios from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';

const D3DImageView = (props)=>{

    const {images} = props;
    // let images = ['https://pim.ivymobi.net:44391/cdn/f19fb95e-eda6-49ba-af93-70ddce588269/ecc0e69a-c40b-44e5-97c1-8620ec5c73b7.png']
    const [sources, setSources] = useState<HTMLImageElement[]>([]);
    // const [loadCount, setLoadCount] = useState(0);
    let loadCount = 0;
    let preIndex = 0;
    let curIndex = 0;
    const onDragMove = (index)=>{
        for(let i=0;i<images.length;i++){
            let pre = document.getElementById(i+'');
            pre.style.visibility='hidden'
        }
        let show = document.getElementById(index%images.length+'');
        show.style.visibility='visible'
        // preIndex = i;
    }
    if(images.length==0)
        return null;
    var x1 = 0;
    var y1 =0;
    var dis = 0;
    var preX =0;
    var btouch = false;
    const onMouseDown = (ev)=>{
        btouch = true;
         x1 = ev.clientX??ev.targetTouches[0].clientX;
         y1 = ev.clientY??ev.targetTouches[0].clientY;
         dis = 0;
         preX =0;
    }
    const onMouseMove =(ev)=>{
        if(!btouch)
        return;
    if(ev.clientX==preX)
        return;
    if((ev.clientX??ev.targetTouches[0].clientX)>preX){
        let inde = ++preIndex;
        let real = 0;
        if(inde<0){
            real  = images.length+preIndex%images.length
        }else{
            real = inde
        }
        onDragMove(real)
    }
    else{
        let inde = --preIndex;
        let real = 0;
        if(inde<0){
            real  = images.length+preIndex%images.length
        }else{
            real = inde   
        }
        onDragMove(real)  
    }
    preX = ev.clientX??ev.targetTouches[0].clientX;
    }
    const onMouseLeave=()=>{
        btouch = false;
    }
    return <Box onTouchMove={onMouseMove} onTouchStart={(ev)=>{
        onMouseDown(ev)
    }} onTouchCancel={onMouseLeave} onMouseLeave={onMouseLeave} onMouseUp={onMouseLeave}  onMouseMove={onMouseMove} onMouseDown={(ev)=>{
        onMouseDown(ev)
        //event的兼容性
        // btouch = true;
        // //获取鼠标按下的坐标
        //  x1 = ev.clientX;
        //  y1 = ev.clientY;
        //  dis = 0;
        //  preX =0;
        // document.ontouchmove=(ev)=>{
        //     //event的兼容性
        //     //获取鼠标移动时的坐标
        //     var x2 = ev.clientX;
        //     var y2 = ev.clientY;
 
        //     //计算出鼠标的移动距离
        //     var x = x2-x1;
        //     var y = y2-y1;
        //     if(ev.clientX-preX>0){
        //         let inde = ++preIndex;
        //         let real = 0;
        //         if(inde<0){
        //             real  = images.length+preIndex%images.length
        //         }else{
        //             real = inde
        //         }
        //         onDragMove(real)
        //     }
        //     else{
        //         let inde = --preIndex;
        //         let real = 0;
        //         if(inde<0){
        //             real  = images.length+preIndex%images.length
        //         }else{
        //             real = inde
        //         }
        //         onDragMove(real) 
        //     }
        //     preX = ev.clientX;
        // }
        // document.onmousemove=(ev)=>{
        //     //event的兼容性
        //     //获取鼠标移动时的坐标
        //     var x2 = ev.clientX;
        //     var y2 = ev.clientY;
 
        //     //计算出鼠标的移动距离
        //     var x = x2-x1;
        //     var y = y2-y1;
        //     if(ev.clientX-preX>0){
        //         let inde = ++preIndex;
        //         let real = 0;
        //         if(inde<0){
        //             real  = images.length+preIndex%images.length
        //         }else{
        //             real = inde
        //         }
        //         onDragMove(real)
        //     }
        //     else{
        //         let inde = --preIndex;
        //         let real = 0;
        //         if(inde<0){
        //             real  = images.length+preIndex%images.length
        //         }else{
        //             real = inde
        //         }
        //         onDragMove(real) 
        //     }
        //     preX = ev.clientX;
        // }
        // document.ontouchend = function(ev){
 
        //     document.onmousemove = null;
 
        // }
        // document.onmouseup = function(ev){
 
        //     document.onmousemove = null;
 
        // }
    }} style={{position:'relative',display:'flex', justifyContent:'center', alignItems:'center',width:'100%', height:'100%'}}>
        {images.map((item,i)=>{
            return <img id={i}   src={item} draggable={false} style={{width:'100%',height:'100%',visibility:preIndex==i?'visible':'hidden',WebkitUserSelect:'none',position:'absolute',left:'0px',top:'0',right:"0px",bottom:'0px',objectFit:"contain"}} onLoad={()=>{
                // setLoadCount(loadCount+1)
                loadCount = loadCount+1;
                let pre = document.getElementById('speed').innerHTML=Math.round(loadCount*100/images.length) +'%';
                if(loadCount==images.length){
                    let pre = document.getElementById('shadow');
                    pre.style.visibility='hidden' 
                    
                }
            }}/> 
        })}
        <Box id='shadow' style={{visibility:'visible',background:'white',position:'absolute',left:'0px',top:'0',right:"0px",bottom:'0px',display:'flex',justifyContent:"center",alignItems:'center  '}}>
            <p id='speed'>{Math.round(loadCount*100/images.length) +'%'}</p>
        </Box>
        <Box style={{width:'20px',height:'20px',position:'absolute',bottom:'0px'}}><img src='https://lite-cdn.yangbentong.com/d6ca9783-c5f9-4dd8-9ca1-b92a95d62e59/6b892de0-b327-4b2d-96fb-88dd607c142a.png' style={{width:'20px',height:'20px'}}/></Box>
    </Box>
}

export default D3DImageView;