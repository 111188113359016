import React from 'react';
import { get } from 'lodash';
import { useFormContext, Controller } from 'react-hook-form';
import {
  Switch,  SwitchProps,
  FormControl, FormLabel, FormControlLabel,
  createStyles, makeStyles, Theme,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  switchrootEnable: {
    '& .MuiSwitch-colorSecondary.Mui-disabled.Mui-checked': {
      color: theme.palette.primary.main,
    },
    '& .MuiSwitch-colorPrimary.Mui-disabled': {
      color: theme.palette.primary.main, 
      opacity:'0.5'
    },
    
    '& .MuiSwitch-colorPrimary.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: theme.palette.primary.main, 
    },
    '& .MuiSwitch-colorSecondary.Mui-disabled.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#f50057'
    }
  },
  switchroot: {
    '& .MuiSwitch-colorSecondary.Mui-disabled.Mui-checked': {
      color: '#f50057',
    },
    
    '& .MuiSwitch-colorSecondary.Mui-disabled.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#f50057'
    }
  },
  labelroot: {
    '&.MuiFormControlLabel-labelPlacementStart': {
      marginLeft: 0,
      marginRight: 0,
      color: '#0000008a'
    }
  }
}));

interface BooleanInputProps extends SwitchProps {
  name: string;
  label?: string;
  title: string;
  backOnChange?:any;
  bDisAndEnable?:any;
}
export default function BooleanInput (props: BooleanInputProps) {
  const {
    name, label = '', title = '', defaultStatus = true, backOnChange = null,bDisAndEnable=false,
    ...rest
  } = props;
  const { control } = useFormContext();
  const classes = useStyles();
  let defaultValue = get(control.defaultValuesRef.current, name, defaultStatus);
  // // console.log('control.defaultValuesRef.currentcontrol.defaultValuesRef.currentcontrol.defaultValuesRef.current', control.defaultValuesRef);
  // if (name==='acl.watermarkable') {
  //   defaultValue = false;
  // }
  return (
    <Controller control={control} name={name}
      defaultValue={defaultValue}
      render={({onChange, onBlur, value, name }) => (
        <FormControl component="fieldset">
          {/* <FormLabel component="legend">{title}</FormLabel> */}
          <FormControlLabel
            label={title}
            labelPlacement="start"
            className={classes.labelroot}
            control={<Switch
              className={bDisAndEnable?classes.switchrootEnable:classes.switchroot}
              onBlur={onBlur}
              name={name}
              checked={value}
              onChange={e => {
                onChange(e.target.checked);
                if (backOnChange)
                  backOnChange(e.target.checked);
              } }
              {...rest}
            />}
          />
        </FormControl>
      )}
    >
    </Controller>
  );
}