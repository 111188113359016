import * as React from 'react';
import { get, isNull } from 'lodash';
import {
  Typography, TypographyProps,
} from '@material-ui/core';
import { format, isValid } from 'date-fns';

import { useShowContext } from './show';
import Label from './label';

type STextFieldProps = TypographyProps & {
  field: string;
  label?: string;
  id?: string;
  emptyText?: string;
}
export default function STextField(props: STextFieldProps) {
  const {
    field,
    id, label = field,
    emptyText = '-',
    ...rest
  } = props;

  const data = useShowContext();
  const value = get(data, field, emptyText);
  const isDate = isValid(new Date(isNull(value) ? '' : value ));

  return (
    <Label id={id} label={label}>
      <Typography {...rest} style={{ marginBottom: 0 }}>
        { isDate ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss') : value ? value : emptyText}
      </Typography>
    </Label>
  );
}